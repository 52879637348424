import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getSearchFacets } from '@/services/search.service';
import { useQuery } from '@tanstack/react-query';

export const useSearch = (options?: object) => {
  const searchFacetsQueryFn = useAuthenticatedQueryFn(getSearchFacets);

  const {
    data: searchFacets = [],
    isPending: searchFacetsArePending,
    isFetching: searchFacetsAreFetching,
    error: searchFacetsError,
    refetch: refetchSearchFacets,
  } = useQuery({
    queryKey: ['searchFacets'],
    queryFn: () => searchFacetsQueryFn(''),
    ...options,
  });

  return {
    searchFacets,
    searchFacetsAreFetching,
    searchFacetsArePending,
    searchFacetsError,
    refetchSearchFacets,
  };
};
