import { FC, useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { DateTime } from 'luxon';
import { Audit } from '@/types/audit';
import { useTree } from '@/hooks/data/tree/useTree';
import { Skeleton } from '@/components/ui/skeleton';
import { useAudit } from '@/hooks/data/audit/useAudit';
import { NodeType } from '@/types/tree';
import { FileClock } from 'lucide-react';
import { usePagination } from '@/hooks/usePagination';
import { CustomPagination } from '@/components/pagination';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { ArrowRightLeft, FilePlus, History, Info, Pen, RefreshCcw, Trash2 } from 'lucide-react';
import { getEventMessage } from '@/types/events';
import { useRouter } from 'next/router';
import { useAuth0 } from '@auth0/auth0-react';

export const AuditLogItem = ({ user, timestamp, event }: Audit) => {
  let action = '';
  const match = event.match(/\.(\w+)$/);
  const dateTime = DateTime.fromISO(timestamp);
  const formattedTimestamp = dateTime.toLocaleString(DateTime.DATETIME_FULL);

  if (match) {
    action = match[1];
  }

  return (
    <div className="z-10 flex flex-row gap-x-2">
      <div
        className={cn(
          'flex max-h-6 min-h-6 min-w-6 max-w-6 items-center justify-center rounded-full ring-4 ring-neutral-100 dark:ring-neutral-900',
          action === 'trashed'
            ? 'bg-red-200 dark:bg-red-900'
            : action === 'renamed'
            ? 'bg-yellow-200 dark:bg-yellow-900'
            : action === 'updated'
            ? 'bg-yellow-200 dark:bg-yellow-900'
            : action === 'restored'
            ? 'bg-emerald-200 dark:bg-emerald-900'
            : action === 'moved'
            ? 'bg-blue-200 dark:bg-blue-900'
            : action === 'created'
            ? 'bg-pink-200 dark:bg-pink-900'
            : 'bg-neutral-200 dark:bg-neutral-700',
        )}
      >
        {action === 'renamed' ? (
          <Pen className="size-3 text-yellow-600 dark:text-yellow-400" />
        ) : action === 'trashed' ? (
          <Trash2 className="size-3 text-red-600 dark:text-red-400" />
        ) : action === 'restored' ? (
          <History className="size-3 text-emerald-600 dark:text-emerald-400" />
        ) : action === 'updated' ? (
          <RefreshCcw className="size-3 text-yellow-600 dark:text-yellow-400" />
        ) : action === 'moved' ? (
          <ArrowRightLeft className="size-3 text-blue-600 dark:text-blue-400" />
        ) : action === 'created' ? (
          <FilePlus className="size-3 text-pink-600 dark:text-pink-400" />
        ) : (
          <Info className="size-3 text-neutral-600 dark:text-neutral-300" />
        )}
      </div>
      <div className="flex flex-col pt-px">
        <div>
          <span className="break-all font-medium">{user?.name}</span>{' '}
          <span className="text-neutral-500 dark:text-neutral-400">{getEventMessage(event)}</span>
        </div>
        <div className="text-xs text-neutral-400 dark:text-neutral-600">{formattedTimestamp}</div>
      </div>
    </div>
  );
};

type AuditLogAccordionProps = {
  multipleAssetSelected: boolean;
  selectedAssetIds: Array<{ id: string; name: string }>;
  currentAccordionItems: Array<string>;
  entity?: string;
  accordionTriggerClassName?: string;
};

export const AuditLogAccordion: FC<AuditLogAccordionProps> = ({
  selectedAssetIds,
  multipleAssetSelected,
  currentAccordionItems,
  entity = null,
  accordionTriggerClassName = '',
}) => {
  const [page, setPage] = useState(1);

  const { currentSelectedType, selectedAlbum, selectedFolder } = useTree();
  const { isAuthenticated } = useAuth0();
  const { query } = useRouter();

  const getAuditId = () => {
    if (entity) {
      return selectedAssetIds[0]?.id;
    } else if (currentSelectedType === NodeType.Assets) {
      return selectedAssetIds?.[selectedAssetIds.length - 1]?.id;
    } else if (currentSelectedType === NodeType.Folders) {
      return selectedFolder;
    } else {
      return selectedAlbum;
    }
  };

  const { audits, auditsArePending } = useAudit(page, 5, getAuditId(), entity ?? currentSelectedType, {
    enabled: currentAccordionItems?.includes('audit-log') && isAuthenticated,
  });

  useEffect(() => {
    //Reset audit page when changing asset selection
    setPage(1);
  }, [selectedAssetIds]);

  const auditLogs = audits?.audit;

  const { offset, limit: pageSize = 0, count: totalCount = 0 } = audits?.pagination ?? {};

  const currentPage = (offset ?? 0) / (pageSize ?? 0) + 1;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize,
  });

  const lastPage = paginationRange?.[paginationRange.length - 1];

  return (
    <AccordionItem className="border-0" value="audit-log" disabled={multipleAssetSelected}>
      <AccordionTrigger
        className={cn(accordionTriggerClassName)}
        // className="h-[49px] border-y border-b-neutral-300 border-t-[#2D2D2D] bg-neutral-200 p-3 hover:no-underline @[18rem]/inspector:py-3 dark:border-b-black dark:bg-[#222222]"
        disabled={multipleAssetSelected}
      >
        Audit Log
      </AccordionTrigger>
      {!multipleAssetSelected && (
        <>
          {auditsArePending ? (
            <AccordionContent className="flex flex-row gap-x-2 pt-5">
              {/* <AccordionContent className="flex flex-row gap-x-2 bg-neutral-200 p-3 pt-5 dark:bg-[#1C1C1C]"> */}
              <Skeleton className="max-h-6 min-h-6 w-full min-w-6 max-w-6 rounded-full bg-neutral-200 dark:bg-neutral-800" />
              <div className="flex w-full flex-col gap-y-1">
                <Skeleton className="h-6 w-11/12 bg-neutral-200 dark:bg-neutral-800" />
                <Skeleton className="h-4 w-3/4 bg-neutral-200 dark:bg-neutral-800" />
              </div>
            </AccordionContent>
          ) : auditLogs && auditLogs.length > 0 ? (
            <AccordionContent className='relative before:absolute before:inset-y-0 before:left-[0.75rem] before:-z-0 before:w-0.5 before:content-[""] before:[background-image:linear-gradient(to_bottom,transparent_0px,rgb(229_229_229)_20px)] dark:before:[background-image:linear-gradient(to_bottom,transparent_0px,rgb(38_38_38)_20px)]'>
              {/* <AccordionContent className='relative bg-neutral-200 text-neutral-600 before:absolute before:inset-y-0 before:left-[0.75rem] before:-z-0 before:w-0.5 before:content-[""] before:[background-image:linear-gradient(to_bottom,transparent_0px,rgb(229_229_229)_20px)] dark:bg-[#1C1C1C] dark:before:[background-image:linear-gradient(to_bottom,transparent_0px,rgb(38_38_38)_20px)]'> */}
              <div className="ml-px flex flex-col gap-y-5 pt-5">
                {auditLogs?.map((log: Audit) => <AuditLogItem key={log.id} {...log} />)}
                {audits?.pagination && audits.pagination.count > audits.pagination.limit && (
                  <CustomPagination
                    totalCount={totalCount}
                    siblingCount={1}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    deselectAssetsOnPageChange={false}
                    handleNext={{
                      onClick() {
                        setPage((prev) => prev + 1);
                      },
                      href: {
                        query,
                      },
                      disabled: currentPage === lastPage,
                    }}
                    handlePrev={{
                      onClick() {
                        setPage((prev) => prev - 1);
                      },
                      href: {
                        query,
                      },
                      disabled: Number(currentPage) === 1 || Number(currentPage) === 0,
                    }}
                    onPageChange={(selectedPage) => setPage(selectedPage ?? 1)}
                    onlyIcons
                  />
                )}
              </div>
            </AccordionContent>
          ) : (
            <AccordionContent className="py-8 text-center text-neutral-600">
              {/* <AccordionContent className="bg-neutral-200 p-3 py-8 text-center text-[#666666] dark:bg-[#1C1C1C]"> */}
              <FileClock className="mx-auto mb-2" size={28} strokeWidth={1.5} />
              No audit logs
            </AccordionContent>
          )}
        </>
      )}
    </AccordionItem>
  );
};
