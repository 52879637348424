export const DeleteTagsCommand = (
  assetId: string,
  oldTags: Array<string>,
  newTags: Array<string>,
  updateTagsFn: (assetId: string, tags: Array<string>) => void,
) => {
  const apply = () => updateTagsFn(assetId, newTags);
  const undo = () => updateTagsFn(assetId, oldTags);
  const redo = () => updateTagsFn(assetId, newTags);

  return {
    apply,
    undo,
    redo,
  };
};
