import { FC, useState, useMemo, KeyboardEvent } from 'react';
import { useSearch } from '@/context/SearchContext';
import { useInstantSearch, useClearRefinements } from 'react-instantsearch';
import { File } from 'lucide-react';
import { useRouter } from 'next/router';
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverAnchor } from '@/components/ui/popover';
import { useSearchBox } from 'react-instantsearch';
import { Input } from './ui/input';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useAlgolia } from '@/context/AlgoliaContext';
import { PATHS } from '@/hooks/useCurrentPage';
import { Search as SearchIcon } from 'lucide-react';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import Image from 'next/image';

export const QuickSearch: FC = () => {
  const [openSearch, setOpenSearch] = useState(false);

  const { isTrashPage } = useCurrentPage();
  const { algoliaBaseClient, algoliaIndexes } = useAlgolia();

  return (
    <InstantSearch
      searchClient={algoliaBaseClient}
      indexName={algoliaIndexes.default}
      future={{ preserveSharedStateOnUnmount: true }}
    >
      <Configure
        attributesToRetrieve={['*']}
        // Limiting search dropdown list to 10 results
        hitsPerPage={10}
        // Only show trashed results on the /trash page
        filters={`trashed:${isTrashPage}`}
      />
      <SearchContent openSearch={openSearch} setOpenSearch={setOpenSearch} algoliaIndexes={algoliaIndexes} />
    </InstantSearch>
  );
};

const SearchContent: FC<{
  openSearch: boolean;
  setOpenSearch: (openSearch: boolean) => void;
  algoliaIndexes: { [key: string]: string; default: string };
}> = ({ openSearch, setOpenSearch, algoliaIndexes }) => {
  const instance = useInstantSearch();
  const results = useMemo(() => instance?.results?.hits || [], [instance?.results?.hits]);
  const totalHits = instance?.results?.nbHits || 0;

  const { push } = useRouter();
  const { refine } = useSearchBox();
  const { refine: clearRefinements } = useClearRefinements();
  const { uiState } = useInstantSearch();
  const { navigateToResults } = useSearch();

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Clearing all refinements before navigation is crucial to ensure consistency between the dropdown search
      // results and the search page. This prevents discrepancies where certain results appear in the dropdown
      // but are excluded on the search page due to active facet filters.
      clearRefinements();

      void push({
        pathname: PATHS.SEARCH,
        query: { query: event.currentTarget?.value },
      });

      setOpenSearch(false);
    }
  };

  return (
    <Popover open={openSearch}>
      <PopoverAnchor asChild>
        <Input
          prependIcon={<SearchIcon className="mr-2 size-3.5 shrink-0 opacity-50" />}
          aria-expanded={openSearch}
          placeholder="Search asset(s)..."
          onChange={(e) => refine(e.target?.value)}
          className="z-20 h-9 max-w-96 bg-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950"
          onFocus={() => setOpenSearch(true)}
          onBlur={() => setOpenSearch(false)}
          onKeyDown={handleKeyDown}
          isLoading={instance.status === 'loading'}
        />
      </PopoverAnchor>
      <PopoverContent className="w-96 p-0" onOpenAutoFocus={(e) => e.preventDefault()}>
        <Command>
          <CommandList>
            <CommandEmpty>No asset(s) found.</CommandEmpty>
            <CommandGroup>
              {results.map((asset) => (
                <CommandItem
                  key={asset.id}
                  onSelect={() => {
                    void push(`/asset/${asset.id}`);
                    setOpenSearch(false);
                  }}
                >
                  {asset.thumbnailUrl ? (
                    <div className="relative mr-2 size-4">
                      <Image src={asset.thumbnailUrl} alt="Thumbnail" fill />
                    </div>
                  ) : (
                    <File className="mr-2 size-4" />
                  )}
                  <p className="line-clamp-1">{asset.name}</p>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
          {
            // Show "See matching results" button only if something was typed in the input field and there are results
            uiState[algoliaIndexes.default]?.query && results.length > 0 && (
              <div className="z-50 flex items-center border-t p-1 dark:border-t-neutral-800">
                <div
                  onClick={() => {
                    // Clearing all refinements before navigation is crucial to ensure consistency between the dropdown search
                    // results and the search page. This prevents discrepancies where certain results appear in the dropdown
                    // but are excluded on the search page due to active facet filters.
                    clearRefinements();

                    void navigateToResults(uiState);

                    setOpenSearch(false);
                  }}
                  className="flex w-full cursor-default select-none items-center justify-center rounded-sm px-2 py-1.5 text-sm text-neutral-600 outline-none hover:bg-neutral-100 hover:text-neutral-900 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50 dark:text-neutral-400 dark:hover:bg-neutral-800 dark:hover:text-neutral-50"
                >
                  See <span className="mx-1 font-extrabold text-black dark:text-white">{totalHits}</span> matching
                  result
                  {totalHits === 1 ? '' : 's'}
                </div>
              </div>
            )
          }
        </Command>
      </PopoverContent>
    </Popover>
  );
};
