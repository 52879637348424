import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getVariantList } from '@/services/variant.service';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useVariantList = (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const variantListQueryFn = useAuthenticatedQueryFn(getVariantList);

  const {
    data: variantList = { variants: [], pagination: { count: 0, limit: 0, offset: 0 } },
    isPending: variantListIsPending,
    isFetching: variantListIsFetching,
    error: variantListError,
    refetch: refetchVariantList,
  } = useQuery({
    queryKey: ['variantList', params.pagination, params.queryString, params.sort],
    queryFn: () => variantListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    variantList,
    variantListIsFetching,
    variantListIsPending,
    variantListError,
    refetchVariantList,
  };
};
