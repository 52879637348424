import { damRequest } from '@/hooks/damRequest';
import { getDestinationsSchema, destinationArraySchema } from '@/types/destination';

const BASE = '/api/v1';

export const getDestinationList = async (params?: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}/destinations`, token);

  return getDestinationsSchema.parse(response);
};

export const setDestination = async (
  { destinationIds, itemId, type }: { destinationIds?: Array<string> | null; itemId?: string; type?: string },
  token?: string,
) => {
  const response = await damRequest('PUT', `${BASE}/${type}/${itemId}/destinations`, token, {
    body: { destinations: destinationIds },
  });

  return destinationArraySchema.parse(response);
};
