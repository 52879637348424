import { damRequest } from '@/hooks/damRequest';
import { shareSchema, shareListSchema, CreateShareParams, UpdateShareParams } from '@/types/share';

const BASE = '/api/v1/internal/nodes';

const getShare = async (params: { nodeId: string; shareId: string }, token?: string) => {
  const response = await damRequest('GET', `${BASE}/${params.nodeId}/shares/${params.shareId}`, token);
  return response;
  // return shareSchema.parse(response);
};

const getShareList = async (
  params: {
    nodeId: string;
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}/${params.nodeId}/shares?offset=${params.pagination?.offset ?? ''}&limit=${
      params.pagination?.limit ?? ''
    }&filterQuery=${params.queryString}&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return shareListSchema.parse(response);
};

const createShare = async ({ nodeId, body }: CreateShareParams, token?: string) => {
  const response = await damRequest('POST', `${BASE}/${nodeId}/shares`, token, { body });

  return response;
};

const editShare = async ({ nodeId, shareId, body }: UpdateShareParams, token?: string) => {
  const response = await damRequest('PUT', `${BASE}/${nodeId}/shares/${shareId}`, token, { body });

  return response;
};

const deleteShare = ({ nodeId, shareId }: { nodeId: string; shareId: string }, token?: string) =>
  damRequest('DELETE', `${BASE}/${nodeId}/shares/${shareId}`, token);

export { getShare, createShare, editShare, getShareList, deleteShare };
