import { damRequest } from '@/hooks/damRequest';
// import { getSearchFacetsSchema } from '@/types/search';

const BASE = '/api/v1/internal';

export const getSearchFacets = async (params?: string, token?: string) => {
  const response = await damRequest('GET', `${BASE}/facets`, token);
  return response;

  //   return getDestinationsSchema.parse(response);
};
