import { damRequest } from '@/hooks/damRequest';
import { trashNodesResponseSchema, getTrashResponseSchema } from '@/types/trash';

const BASE = '/api/v1/internal/trash';

const trashNodesQuery = async (params: { nodes: Array<string> }, token?: string) => {
  const response = await damRequest('POST', BASE, token, {
    body: params,
  });
  return trashNodesResponseSchema.parse(response);
};

const restoreNodesQuery = async (params: { nodes: Array<string> }, token?: string) => {
  const response = await damRequest('POST', `${BASE}/restore`, token, {
    body: params,
  });
  return trashNodesResponseSchema.parse(response);
};

const getTrashQuery = async (params?: { pagination?: { offset: number; limit: number } }, token?: string) => {
  const query = new URLSearchParams({
    offset: String(params?.pagination?.offset ?? 0),
    limit: String(params?.pagination?.limit ?? 50),
  }).toString();
  const response = await damRequest('GET', `${BASE}?${query}`, token);
  return getTrashResponseSchema.parse(response);
};

const emptyTrashQuery = async (params: void, token?: string) => {
  const response = await damRequest('DELETE', BASE, token);
  return trashNodesResponseSchema.parse(response);
};

export { trashNodesQuery, restoreNodesQuery, getTrashQuery, emptyTrashQuery };
