import { z } from 'zod';

export const auditSchema = z.object({
  id: z.string(),
  timestamp: z.string(),
  user: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional(),
  event: z.string(),
  objectType: z.string(),
  objectId: z.string(),
  /* details: z
    .object({
      name: z.string(),
    }) */
});
export type Audit = z.infer<typeof auditSchema>;

export const getAuditsSchema = z.object({
  audit: z.array(auditSchema),
  pagination: z
    .object({
      offset: z.number(),
      limit: z.number(),
      count: z.number(),
    })
    .optional(),
});

export type GetAudits = z.infer<typeof getAuditsSchema>;
