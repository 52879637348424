import { File } from 'lucide-react';

export const MetadataDocuments = ({ value }: { value: Array<string> }) => (
  <div className="flex flex-col gap-1.5 px-3 py-1">
    {value
      ? value?.map((document) => (
          <div key={document} className="flex items-center gap-2">
            <File className="size-4 stroke-neutral-500 dark:stroke-neutral-400" strokeWidth={2.2} />
            {document}
          </div>
        ))
      : 'No documents'}
  </div>
);
