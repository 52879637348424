import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { getUser, getUsers } from '@/services/users.service';

export const useUserList = (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const userListQueryFn = useAuthenticatedQueryFn(getUsers);

  const {
    data: userList = {
      users: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: userListIsPending,
    isFetching: userListIsFetching,
    error: userListError,
    refetch: refetchUserList,
  } = useQuery({
    queryKey: ['userList', params.pagination, params.queryString, params.sort],
    queryFn: () => userListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    userList,
    userListIsFetching,
    userListIsPending,
    refetchUserList,
    userListError,
  };
};

export const useUser = (id: string, options?: object) => {
  const userQueryFn = useAuthenticatedQueryFn(getUser);

  const {
    data: user = {},
    isPending: userIsPending,
    isFetching: userIsFetching,
    error: userError,
    refetch: refetchUser,
  } = useQuery({
    queryKey: ['user', id],
    queryFn: () => userQueryFn({ id }),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    user,
    userIsFetching,
    userIsPending,
    refetchUser,
    userError,
  };
};
