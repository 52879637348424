import { Check, X } from 'lucide-react';

export const StatusIndicator = ({ row, value }: { row: any; value: string }) => {
  return (
    <div className="flex items-center">
      {row.getValue(value) === true ? (
        <Check data-cy={`${row.getValue(value)}-checkmark-row-id-${row.id}`} className="size-5" />
      ) : (
        <X data-cy={`${row.getValue(value)}-xicon-row-id-${row.id}`} className="size-5" />
      )}
    </div>
  );
};
