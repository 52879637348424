import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useInstantSearch } from 'react-instantsearch';
import { useSearch } from '@/context/SearchContext';
import { useAlgolia } from '@/context/AlgoliaContext';
import { useSearchParams } from 'next/navigation';
import { DatePickerWithRange } from '@/components/date-picker-with-range';
import { fromUnixTime, startOfDay, endOfDay, getUnixTime } from 'date-fns';

export function SearchWithDateFilter() {
  const [date, setDate] = useState<DateRange | undefined>();
  const [hasInitialized, setHasInitialized] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { algoliaIndexes } = useAlgolia();
  const { uiState } = useInstantSearch();
  const { navigateToResults } = useSearch();

  const searchParams = useSearchParams();

  useEffect(() => {
    // Check if this has run before
    if (!hasInitialized) {
      // Using uiState.filters directly to pre-populate the datepicker ensures that only valid, Algolia-applied filters are used.
      // Algolia validates filters and rejects invalid ones, so by relying on uiState, we avoid populating the datepicker with filters that aren’t actually applied in Algolia.
      const filters = uiState[algoliaIndexes.default]?.configure?.filters || '';

      if (filters) {
        const fromMatch = filters.match(/createdAt\s>=\s(\d+)/);
        const toMatch = filters.match(/createdAt\s<=\s(\d+)/);

        if (fromMatch && toMatch) {
          const from = startOfDay(fromUnixTime(parseInt(fromMatch[1], 10)));
          const to = endOfDay(fromUnixTime(parseInt(toMatch[1], 10)));
          setDate({ from, to });
        }
      }
      setHasInitialized(true); // Set the flag to true after initial run so that we don't end up in an infinite loop
    }
  }, [searchParams, hasInitialized]);

  const handleDateRangeChange = (range: DateRange | undefined) => {
    if (range?.from && range?.to) {
      const fromDate = getUnixTime(range.from);
      const toDate = getUnixTime(range.to);

      const newDateFilter = `createdAt >= ${fromDate} AND createdAt <= ${toDate}`;

      // Get the existing filters, excluding any old createdAt filter
      const existingFilters = (uiState[algoliaIndexes.default].configure?.filters || '')
        .split(' AND ')
        .filter((filter) => !filter.includes('createdAt >=') && !filter.includes('createdAt <='))
        .join(' AND ')
        .trim();

      // Combine the existing filters with the new date filter
      const combinedFilters = [existingFilters, newDateFilter].filter(Boolean).join(' AND ');

      // Navigate with the updated filters
      void navigateToResults({
        [algoliaIndexes.default]: {
          ...uiState[algoliaIndexes.default],
          configure: {
            ...uiState[algoliaIndexes.default].configure,
            filters: combinedFilters,
          },
        },
      });
    } else {
      // If no date range, just use existing filters without any date constraints
      void navigateToResults({
        [algoliaIndexes.default]: {
          ...uiState[algoliaIndexes.default],
          configure: {
            ...uiState[algoliaIndexes.default].configure,
            filters: (uiState[algoliaIndexes.default].configure?.filters || '')
              .split(' AND ')
              .filter((filter) => !filter.includes('createdAt >=') && !filter.includes('createdAt <='))
              .join(' AND ')
              .trim(),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (date?.from && date?.to) {
      handleDateRangeChange(date);
    }
  }, [date]);

  const clearDateRange = () => {
    setDate(undefined);
    handleDateRangeChange(undefined);
    setIsOpen(false);
  };

  return (
    <DatePickerWithRange
      date={date}
      setDate={setDate}
      onDateChange={handleDateRangeChange}
      onClear={clearDateRange}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="transition duration-150 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2 focus-visible:duration-150 dark:border-neutral-800 dark:focus-visible:ring-neutral-500 dark:focus-visible:ring-offset-neutral-950"
    />
  );
}
