import { Skeleton } from '@/components/ui/skeleton';

export const DataTableLoadingSkeleton = () => (
  <div className="flex w-full flex-col gap-y-px">
    <Skeleton className="h-[48px] w-full rounded-none bg-neutral-200 dark:bg-[#2D2D2D]" />
    <div className="flex w-full flex-col gap-px">
      {Array.from(Array(10)).map((_, index) => (
        <div className="flex w-full flex-row gap-px" key={`skeleton-${index}`}>
          <Skeleton className="h-9 w-1/4 rounded-none bg-neutral-200 dark:bg-[#222222]" />
          <Skeleton className="h-9 w-1/6 rounded-none bg-neutral-200 dark:bg-[#222222]" />
          <Skeleton className="h-9 w-1/3 rounded-none bg-neutral-200 dark:bg-[#222222]" />
          <Skeleton className="h-9 w-1/4 rounded-none bg-neutral-200 dark:bg-[#222222]" />
        </div>
      ))}
    </div>
  </div>
);
