import { DialogMode } from '@/context/DialogContext';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getFolder } from '@/services/folder.service';
import { getAlbum } from '@/services/album.service';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useFolderOrAlbum = (
  openDialogId?: string | null,
  mode?: DialogMode | null,
  form?: {
    reset: (data: any) => void;
  },
  id?: string,
) => {
  const getFolderWithAuth = useAuthenticatedQueryFn(getFolder);
  const getAlbumWithAuth = useAuthenticatedQueryFn(getAlbum);

  const isAlbum = mode === 'renameAlbum' || mode === 'createAlbum';

  const queryFn = isAlbum ? getAlbumWithAuth : getFolderWithAuth;
  const queryKeyPrefix = isAlbum ? 'albumData' : 'folderData';

  // queries
  const {
    data: item,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [queryKeyPrefix, { id }],
    queryFn: async () => {
      const data = await queryFn(id);
      form?.reset(data);
      return data;
    },
    placeholderData: keepPreviousData,
    enabled:
      openDialogId === 'editCreateFolderAlbum' && (mode === 'renameFolder' || mode === 'renameAlbum') && Boolean(id),
  });

  return {
    item,
    refetchFolderOrAlbum: refetch,
    error,
    isLoading,
    isFetching,
    isAlbum,
  };
};
