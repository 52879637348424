export const AddAssetCommand = (addAssets: any, removeAssets: any, albumId: string, assetIds: Array<string>) => {
  const apply = (): any => {
    return addAssets({ albumId: albumId, assetIds: assetIds });
  };

  const undo = (): void => {
    void removeAssets({ albumId: albumId, assetIds: assetIds });
  };

  const redo = (): void => {
    void apply();
  };

  return {
    apply,
    undo,
    redo,
  };
};
