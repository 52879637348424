import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b border-neutral-200 dark:border-neutral-800', className)}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, disabled, ...props }, ref) => {
  return disabled ? (
    <Tooltip>
      <TooltipTrigger
        className={cn(
          'text-md flex flex-1 items-center justify-between py-2 font-medium text-neutral-300 transition-all @[18rem]/inspector:py-4 dark:text-neutral-500',
          className,
        )}
      >
        {children}
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent className="text-center">
          Multiple assets
          <br />
          are selected
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  ) : (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'text-md flex flex-1 items-center justify-between py-2 font-medium transition-all hover:underline @[18rem]/inspector:py-4 dark:disabled:text-neutral-500 [&[data-state=open]>svg]:rotate-180',
          className,
        )}
        {...props}
      >
        {children}

        {!disabled && (
          <ChevronDownIcon className="size-4 shrink-0 text-neutral-500 transition-transform duration-200 dark:text-neutral-400" />
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
