import { useTree } from '@/hooks/data/tree/useTree';
import { TrashNodesResponse } from '@/types/trash';

export const TrashNodesCommand = (nodes: Array<string>) => {
  const { trashNodes, restoreNodes } = useTree();

  const apply = (): Promise<TrashNodesResponse> => {
    return trashNodes({ nodes });
  };

  const undo = (): void => {
    restoreNodes({ nodes });
  };

  const redo = (): void => {
    apply();
  };

  return {
    apply,
    undo,
    redo,
  };
};
