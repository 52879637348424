import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DefaultError, useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { unDeleteFolder, deleteFolder } from '@/services/folder.service';
import { Folder } from '@/types/folder';
import { useTree } from '@/hooks/data/tree/useTree';
import { getFolder } from '@/services/folder.service';

export const useFolder = (
  id?: string,
  form?: {
    reset: (data: any) => void;
  },
  options?: object,
) => {
  const getFolderWithAuth = useAuthenticatedQueryFn(getFolder);
  const authUnDeleteFolder = useAuthenticatedQueryFn(unDeleteFolder);
  const authDeleteFolder = useAuthenticatedQueryFn(deleteFolder);
  const { refetchTree } = useTree();

  const {
    data: folder,
    isLoading: isFolderLoading,
    isPending: isFolderPending,
    isFetching: isFolderFetching,
    error: folderError,
    refetch,
  } = useQuery({
    queryKey: ['folder', id],
    queryFn: id
      ? async () => {
          const data = await getFolderWithAuth(id);
          form?.reset(data);
          return data;
        }
      : () => undefined,
    placeholderData: keepPreviousData,
    enabled: false,
    ...options,
  });

  const { mutateAsync: unDeleteFolderMutation } = useMutation<
    Folder,
    DefaultError,
    Parameters<typeof unDeleteFolder>['0']
  >({
    mutationFn: (params) => authUnDeleteFolder(params),
    onSettled: () => {
      refetchTree();
    },
  });

  const { mutateAsync: deleteFolderMutation } = useMutation<Folder, DefaultError, Parameters<typeof deleteFolder>['0']>(
    {
      mutationFn: (params) => authDeleteFolder(params),
      onSettled: () => {
        refetchTree();
      },
    },
  );

  return {
    folder,
    refetchFolder: refetch,
    isFolderFetching,
    isFolderLoading,
    isFolderPending,
    error: folderError,
    unDeleteFolderMutation,
    deleteFolderMutation,
  };
};
