import { cn } from '@/lib/utils';
import { Tree } from '@/components/sidebar/tree';
import { useTreeContext } from '@/context/TreeContext';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Button } from '../ui/button';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { Input } from '../ui/input';
import {
  ChevronsDownUp,
  FolderPlusIcon,
  ListTree,
  Search,
  Workflow,
  Tags,
  RectangleEllipsis,
  Users,
  Copy,
} from 'lucide-react';
import { useRouter } from 'next/router';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { SearchOptions } from '@/components/sidebar/search-options';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '@/components/ui/context-menu';
import { useDialog } from '@/context/DialogContext';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useTree } from '@/hooks/data/tree/useTree';
import { SidebarNav } from '@/components/sidebar/sidebar-nav';
import { ChevronsRight } from 'lucide-react';
import { TenantUserRole } from '@/types/tenantUserRole';
import { useAccount } from '@/hooks/data/account/useAccount';
import { useAuth0 } from '@auth0/auth0-react';

export const styles = 'text-neutral-600 dark:text-neutral-400 duration-150 select-none';
export const hoverStyles = 'hover:bg-neutral-200 hover:text-black dark:hover:bg-neutral-800 dark:hover:text-white';
export const dropStyle = 'bg-neutral-200 text-black dark:bg-neutral-800 dark:text-white';
export const selectedStyles =
  'bg-neutral-200 text-neutral-950 hover:bg-neutral-300 hover:text-black dark:bg-neutral-950 dark:text-white dark:hover:bg-black dark:hover:text-white';

export function Sidebar({ className }: { className?: string }) {
  const { setOpenIds, openIds, includeSubFolderAssets } = useTreeContext();
  const { push, query } = useRouter();
  const { deselectAllAssets } = useAssetSelection();
  const { isTrashPage, isSearchPage, isManagePage } = useCurrentPage();
  const { openModal } = useDialog();
  const { toggleSubFolders } = useTree();
  const { profile } = useAccount();
  const { isAuthenticated } = useAuth0();
  const { userRole } = profile;

  return isManagePage ? (
    <div className="relative flex h-screen flex-col space-y-px pt-20">
      <SidebarNav
        section={{
          title: 'Configuration',
          icon: ChevronsRight,
        }}
        links={[
          {
            title: 'Workflows',
            icon: Workflow,
            path: '/manage/workflows',
            disabled: false,
          },
          {
            title: 'Tags',
            icon: Tags,
            path: '/manage/tags',
            disabled: false,
          },
          {
            title: 'Metadata',
            icon: RectangleEllipsis,
            path: '/manage/metadata',
            disabled: false,
          },
          {
            title: 'Variants',
            icon: Copy,
            path: '/manage/variants',
            disabled: true,
          },
        ]}
      />
      {isAuthenticated && userRole === TenantUserRole.Owner && (
        <SidebarNav
          section={{
            title: 'Management',
            icon: ChevronsRight,
          }}
          links={[
            {
              title: 'Users',
              icon: Users,
              path: '/manage/users',
              disabled: false,
            },
          ]}
        />
      )}
    </div>
  ) : (
    <div className={cn('flex h-full flex-col @container/sidebar', className)}>
      {isSearchPage ? (
        <SearchOptions className="flex grow" />
      ) : (
        <>
          <div className={cn('relative flex w-full space-x-2 px-3 py-2 before:pointer-events-none dark:bg-[#222222]')}>
            <div className="relative flex grow">
              <Input
                prependIcon={<Search className="mr-2 size-3.5 shrink-0 opacity-50" />}
                className="z-10 h-9 w-full shadow-sm focus-within:ring-offset-neutral-100 dark:bg-neutral-900 dark:focus-within:ring-offset-neutral-900"
                placeholder="Search directory"
              />
            </div>
            <ToggleGroup type="multiple" variant="default" size="sm">
              <Tooltip>
                <TooltipTrigger asChild>
                  <ToggleGroupItem
                    value="includeSubfolderAssets"
                    aria-label="Include Subfolders"
                    className={cn(query?.folder && 'data-[state=on]:bg-white')}
                    onClick={toggleSubFolders}
                    disabled={query?.folder ? false : true}
                  >
                    <ListTree className="size-6" />
                  </ToggleGroupItem>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{includeSubFolderAssets ? `Hide` : `Show`} SubFolders assets</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <ToggleGroupItem
                    value="collapse"
                    aria-label="Toggle tree collapse"
                    onClick={() => setOpenIds([])}
                    className="hidden @[12.5rem]/sidebar:flex"
                    disabled={openIds.length === 0}
                  >
                    <ChevronsDownUp className="size-6" />
                  </ToggleGroupItem>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Collapse</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </ToggleGroup>
          </div>
          <ContextMenu>
            <div className="relative mt-px h-[calc(100vh-53px)] overflow-x-hidden overflow-y-scroll bg-[#2C2C2C]">
              <div className="flex grow border-b border-[#444444] px-3 pb-2 pt-1">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    deselectAllAssets();

                    void push({
                      pathname: '/trash',
                      query: { results: 50, page: 1 },
                    });
                  }}
                  className={cn(
                    'h-9 w-full justify-start pl-5 text-base font-light',
                    styles,
                    hoverStyles,
                    isTrashPage && selectedStyles,
                  )}
                >
                  <TrashIcon className="size-6 min-w-4 text-red-500" strokeWidth={2} />
                  <span className="ml-2 hidden @[9rem]/sidebar:block">Trash</span>
                </Button>
              </div>
              <ContextMenuTrigger className="h-full flex-1">
                <Tree className="flex grow px-3 pb-4 pt-1" />
              </ContextMenuTrigger>
            </div>
            <ContextMenuContent>
              <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'createFolder')}>
                <FolderPlusIcon className="mr-2 size-4" />
                New Folder
              </ContextMenuItem>
            </ContextMenuContent>
          </ContextMenu>
        </>
      )}
    </div>
  );
}
