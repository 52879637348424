import { RowEntity } from '@/context/DatatableSelectionContext';
import React, { HTMLProps, Ref, useEffect, useRef } from 'react';

type TagFieldsProps = {
  tag: RowEntity;
};

export const TagFields = ({ tag }: TagFieldsProps) => {
  return <div>Tags</div>;
};
