import { z } from 'zod';
import { TrashRootNode, trashRootNodeSchema } from '@/types/tree';
import { paginationSchema } from '@/types/pagination';

export const trashNodesResponseSchema = z.object({
  nodes: z.array(z.string()),
});

export type TrashNodesResponse = z.infer<typeof trashNodesResponseSchema>;

export const getTrashResponseSchema = z.object({
  trash: z.array(trashRootNodeSchema),
  pagination: paginationSchema,
});

export type GetTrashResponse = z.infer<typeof getTrashResponseSchema>;

export type TrashCardProps = {
  root: TrashRootNode;
  className?: string;
  isSelected: boolean;
  onClick: () => void;
  view?: 'tile' | 'list';
};
