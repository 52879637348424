import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { useDialog } from '@/context/DialogContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { error as errorLog } from '@/utilities/log';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { deleteShare } from '@/services/share.service';
import { ToastAction } from '@/components/ui/toast';

interface ShareDialogDefaultValue {
  id: string;
  nodeId: string;
}

export const DeleteShareAlertDialog: FC = () => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId, dialogDefaultValue } = useDialog();

  const shareDefaultValue = dialogDefaultValue as ShareDialogDefaultValue | null;

  const queryClient = useQueryClient();

  const deleteShareWithAuth = useAuthenticatedQueryFn(deleteShare);

  const deleteShareMutation = useMutation({
    mutationFn: deleteShareWithAuth,
    onSuccess: () => {
      toast({
        title: 'Share deleted',
        description: `Share ${shareDefaultValue?.id} has been deleted successfully.`,
      });

      void queryClient.invalidateQueries({ queryKey: ['shareList'] });
    },
    onError: (err) => {
      errorLog('Action failed:', err);

      toast({
        variant: 'destructive',
        title: 'Error',
        description: err.message ?? 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    },
  });

  return (
    <AlertDialog open={openDialogId === 'deleteShareConfirmation'} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>{`Are you sure you want to delete this share ${shareDefaultValue?.id}`}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-600"
            onClick={() => {
              if (shareDefaultValue && 'id' in shareDefaultValue) {
                deleteShareMutation.mutate({
                  nodeId: shareDefaultValue?.nodeId,
                  shareId: shareDefaultValue?.id,
                });
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
