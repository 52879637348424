import { useToast } from '@/components/ui/use-toast';
import React from 'react';

type CopyTypes = (text: string, successMessage?: string, errorMessage?: string) => void;

const useClipboard = (): { copy: CopyTypes } => {
  const { toast } = useToast();

  const copy: CopyTypes = (
    text: string,
    successMessage = 'Copied to the clipboard!',
    errorMessage = 'Failed to copy to clipboard',
  ) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: 'Success',
          description: successMessage,
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: errorMessage,
        });
      });
  };

  return { copy };
};

export default useClipboard;
