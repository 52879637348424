import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';
import { RemoveAssetsResponse } from '@/types/album';

export const RemoveAssetsCommand = (albumId: string, assetIds: Array<string>) => {
  const { removeAssets, addAssets } = useAlbumAssets();

  const apply = (): Promise<RemoveAssetsResponse> => {
    return removeAssets({ albumId: String(albumId), assetIds: assetIds });
  };

  const undo = (): void => {
    void addAssets({ albumId: String(albumId), assetIds: assetIds });
  };

  const redo = (): void => {
    void apply();
  };

  return {
    apply,
    undo,
    redo,
  };
};
