import { FC, useEffect, useRef, useState, RefObject, useCallback } from 'react';
import { cn } from '@/lib/utils';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FieldComponents } from '@/components/inspector/metadata-accordion/metadata-field-components';
import { FormField, FormItem, FormLabel, FormMessage, FormControl, FormDescription } from '@/components/ui/form';
import { MetadataField } from '@/types/metadata';
import { useMetadataList } from '@/hooks/metadata/useMetadata';
import { Skeleton } from '@/components/ui/skeleton';
import { Asset, assetWorkflowMetadataSchema } from '@/types/asset';
import { Button } from '@/components/ui/button';
import { Info, Save, X } from 'lucide-react';
import { throttle, debounce } from 'lodash';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { saveAssetMetadata } from '@/services/metadata.service';
import { log } from '@/utilities/log';
import { useToast } from '@/components/ui/use-toast';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { ulid } from 'ulid';
import { Separator } from '@/components/ui/separator';
import { useAuth0 } from '@auth0/auth0-react';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';

export const MetadataEditor: FC<{
  currentAccordionItems: Array<string>;
  asset?: Asset;
  scrollRef?: RefObject<HTMLDivElement>;
}> = ({ currentAccordionItems, asset, scrollRef }) => {
  const { toast } = useToast();
  const { isAuthenticated } = useAuth0();

  const queryClient = useQueryClient();
  const saveButtonRef = useRef<HTMLDivElement>(null);
  const formContainerRef = useRef<HTMLFormElement>(null);

  // State to track "Save Metadata" button position
  const [isAtBottom, setIsAtBottom] = useState(false);

  const { metadataList, metadataListIsFetching } = useMetadataList(
    {
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        value: SortOrderValues.CREATED_AT,
        order: SortOrderOptions.ASC,
      },
      queryString: '',
    },
    { enabled: isAuthenticated },
  );

  const { metadata } = metadataList;

  const form = useForm({
    resolver: zodResolver(assetWorkflowMetadataSchema),
    defaultValues: asset?.metadata as Record<string, any>,
  });

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  // Reset the form when the asset changes, helps with isFormEdited being marked as dirty if the component remounts
  useEffect(() => {
    form.reset(asset?.metadata as Record<string, any>);
  }, [asset, form]);

  const isFormEdited = form.formState.isDirty;
  const bottomMargin = 24;

  const handleScroll = useCallback(() => {
    const formContainer = formContainerRef.current;
    if (formContainer) {
      const rect = formContainer.getBoundingClientRect();
      const isVisible = rect.bottom <= window.innerHeight - bottomMargin && rect.bottom >= 0;
      setIsAtBottom(isVisible);
    }
  }, [bottomMargin]);

  const throttledHandleScroll = throttle(handleScroll, 1000);
  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    const container = scrollRef?.current;
    if (container) {
      container.addEventListener('scroll', throttledHandleScroll);
      container.addEventListener('scroll', debouncedHandleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', throttledHandleScroll);
        container.removeEventListener('scroll', debouncedHandleScroll);
      }
    };
  }, [scrollRef, throttledHandleScroll, debouncedHandleScroll]);

  const saveAssetMetadataWithAuth = useAuthenticatedQueryFn(saveAssetMetadata);

  const saveAssetMetadataMutation = useMutation({
    mutationFn: saveAssetMetadataWithAuth,
    onSuccess: () => {
      toast({
        title: 'Metadata Saved',
        description: 'Metadata has been saved successfully.',
      });

      void queryClient.invalidateQueries({ queryKey: ['asset'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      log(err);
    },
  });

  function onSubmit(values: z.infer<typeof assetWorkflowMetadataSchema>) {
    saveAssetMetadataMutation.mutate({
      asset_id: asset?.id,
      metadata: values,
    });
  }

  const renderField = (field: MetadataField) => {
    const Component = FieldComponents[field.type];
    if (!Component) {
      return null;
    }

    let options: Array<{ value: string; label: string }> = [];
    if (Array.isArray(field.options)) {
      options = [];
    } else if (field.options?.choices) {
      options = Object.entries(field.options.choices).map(([value, label]) => ({ value, label }));
    }

    return (
      <FormField
        key={field.slug}
        name={field.slug}
        control={form.control}
        render={({ field: controllerField }) => (
          <>
            <FormItem
              className={cn(
                'flex @[30rem]/inspector:gap-x-3',
                field.type === 'boolean'
                  ? 'flex-col gap-3 @[24rem]/inspector:flex-row @[24rem]/inspector:items-center'
                  : 'flex-col @[30rem]/inspector:flex-row',
              )}
            >
              <div
                className={cn(
                  'flex items-start gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end',
                  field.type === 'boolean' && 'order-2 @[30rem]/inspector:order-1',
                )}
              >
                <FormLabel
                  htmlFor={field.slug}
                  className={cn(
                    'leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:text-right',
                    field.type === 'boolean' ? '@[30rem]/inspector:mr-1.5' : '@[30rem]/inspector:mt-2',
                  )}
                >
                  {field.name}
                </FormLabel>
                {field.description && (
                  <TooltipProvider key={ulid()} delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger
                        asChild
                        className={cn(
                          'hidden @[24rem]/inspector:flex @[30rem]/inspector:order-1',
                          field.type !== 'boolean' && '@[30rem]/inspector:mt-2',
                        )}
                      >
                        <Info className="size-3.5 text-neutral-600" />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent className="max-w-[300px]">
                          <p>{field.description}</p>
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  </TooltipProvider>
                )}
              </div>
              <FormControl
                className={cn(
                  field.type === 'boolean'
                    ? 'order-2 !mt-1 @[24rem]/inspector:order-1'
                    : '@[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2',
                )}
              >
                <div className="relative">
                  <Component
                    {...controllerField}
                    placeholder={field.type === 'date' ? 'YYYY-MM-DD' : field.name}
                    options={options}
                    onChange={(value: any) => {
                      if (field.type === 'multi_select') {
                        const newValues = (value as Array<{ value: string; label: string }>).map(
                          (option) => option.value,
                        );
                        controllerField.onChange(newValues);
                      } else {
                        controllerField.onChange(value);
                      }
                    }}
                    className={cn(
                      field.type === 'text' && 'w-full',
                      ['text', 'string', 'float', 'integer', 'select', 'multi_select', 'date'].includes(field.type) &&
                        '@[30rem]/inspector:!mt-0',
                      field.type === 'boolean' && '@[30rem]/inspector:!mt-0.5',
                      ['text', 'string', 'float', 'integer', 'select', 'date'].includes(field.type) &&
                        'bg-white focus-within:ring-offset-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950',
                      field.type === 'date' &&
                        'bg-white focus-within:ring-offset-neutral-100 hover:bg-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950 dark:hover:bg-neutral-950',
                      ['text', 'string', 'float', 'integer', 'date'].includes(field.type) && '!pr-0',
                    )}
                    // Include Input's `appendIcon` property with an "X" button only if field type is text, string, float, date or integer and if controllerField.value contains value
                    {...(['text', 'string', 'float', 'integer', 'date'].includes(field.type) &&
                      controllerField.value && {
                        appendIcon: (
                          <Button
                            onClick={() => controllerField.onChange('')}
                            variant="ghost"
                            className="mr-px px-3 !text-neutral-400 duration-150 hover:bg-transparent hover:!text-red-300 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-red-800  dark:focus-visible:ring-neutral-900"
                            aria-label="Clear field"
                          >
                            <X className="size-3" />
                          </Button>
                        ),
                      })}
                  />
                  <FormMessage />
                </div>
              </FormControl>
              {field.description && (
                <FormDescription
                  className={cn('block @[24rem]/inspector:hidden', field.type === 'boolean' && 'order-3')}
                >
                  {field.description}
                </FormDescription>
              )}
            </FormItem>
            <Separator className="block @[24rem]/inspector:hidden" />
          </>
        )}
      />
    );
  };

  return metadataListIsFetching ? (
    <div className="space-y-5 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]">
      {Array.from(Array(2)).map((_, index) => (
        <div
          className="flex flex-col items-center gap-y-2 space-y-2 @[30rem]/inspector:flex-row @[30rem]/inspector:gap-x-3"
          key={index}
        >
          <div className="flex w-full items-center gap-x-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end">
            <Skeleton className={cn('order-1 h-5 @[30rem]/inspector:order-2', index === 0 ? 'w-1/2' : 'w-1/3')} />
            {index === 1 && <Skeleton className="order-2 size-4 rounded-full @[30rem]/inspector:order-1" />}
          </div>
          <Skeleton className="!m-0 h-8 w-full @[30rem]/inspector:w-1/2" />
        </div>
      ))}
    </div>
  ) : (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="relative flex flex-col space-y-6 pb-14 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
        ref={formContainerRef}
      >
        {metadata.map((field) => renderField(field))}

        {currentAccordionItems?.includes('metadata') && (
          <div
            ref={saveButtonRef}
            className={cn(
              'bottom-0 left-0 right-0 z-50 flex ',
              isAtBottom || !isFormEdited
                ? 'absolute @[21rem]/inspector:justify-end'
                : 'fixed justify-center px-[28px] py-6',
            )}
          >
            <Button
              type="submit"
              variant={isFormEdited ? 'default' : 'outline'}
              className="w-full justify-center @[21rem]/inspector:w-auto"
              disabled={metadataListIsFetching || !isFormEdited}
            >
              <Save className="mr-2 size-4" />
              Save Metadata
            </Button>
            {!isAtBottom && isFormEdited && (
              <>
                <div className="pointer-events-none absolute inset-0 -top-14 -z-10 [background-image:linear-gradient(to_top,rgb(245_245_245)_30%,transparent_100%)] dark:[background-image:linear-gradient(to_top,rgb(23_23_23)_30%,transparent_100%)]" />
                <div className="pointer-events-none absolute inset-0 -top-5 -z-10 backdrop-blur-[5px] [mask-image:linear-gradient(to_top,white_60%,transparent_100%)]" />
              </>
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
};
