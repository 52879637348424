import { useEffect, useMemo } from 'react';
import { cn } from '@/lib/utils';
import { Link, Plus, Share2Icon, Trash2Icon } from 'lucide-react';
import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Skeleton } from '@/components/ui/skeleton';
import { useAuth0 } from '@auth0/auth0-react';
import { useSharesList } from '@/hooks/shares/useShares';
import { Badge } from '@/components/ui/badge';
import { useDialog } from '@/context/DialogContext';
import { EditShareDialog } from '../edit-dialogs/edit-share-dialog';
import { DeleteShareAlertDialog } from '../delete-dialogs/delete-share-alert-dialog';
import useClipboard from '@/hooks/useClipboard';
import { useRouter } from 'next/router';
import { format } from 'date-fns';
import { CreateShareDialog } from '../create-dialogs/create-share-dialog';

export const SharesAccordion = ({ currentAccordionItems }: { currentAccordionItems: Array<string> }) => {
  const { dialogMode, openModal } = useDialog();
  const { copy } = useClipboard();
  const { isAuthenticated } = useAuth0();
  const { query } = useRouter();
  const { folder, album } = query;

  const nodeId = useMemo(() => {
    if (album) {
      return album as string;
    } else {
      return folder as string;
    }
  }, [folder, album]);

  const { shareList, shareListIsFetching, refetchShareList } = useSharesList(
    {
      nodeId,
      pagination: {
        offset: null,
        limit: null,
      },
      sort: {
        // value: SortOrderValues.CREATED_AT,
        // order: SortOrderOptions.ASC,
        value: 'created_at',
        order: 'asc',
      },
      queryString: '',
    },
    { enabled: currentAccordionItems?.includes('shares') && isAuthenticated },
  );

  const { shares } = shareList;

  useEffect(() => {
    if (currentAccordionItems?.includes('shares')) {
      void refetchShareList();
    }
  }, [currentAccordionItems]);

  const baseShareUrl = `${window.location.host}/share`;
  const baseShareHref = `${window.location.origin}/share`;

  return (
    <AccordionItem value="shares">
      <AccordionTrigger>Sharing</AccordionTrigger>
      {shareListIsFetching ? (
        <AccordionContent className="m-1 flex flex-col">
          <div className="flex flex-col gap-2.5 @[18rem]/inspector:px-2">
            {Array.from(Array(2)).map((_, index) => (
              <div className="flex w-full gap-x-3" key={index}>
                <Skeleton className="max-h-5 min-h-5 w-9 min-w-9 max-w-9 rounded-full bg-neutral-200 dark:bg-neutral-800" />
                <Skeleton className={cn('h-5 bg-neutral-200 dark:bg-neutral-800', index === 0 ? 'w-3/6' : 'w-2/5')} />
              </div>
            ))}
          </div>
        </AccordionContent>
      ) : shares && shares.length > 0 ? (
        <AccordionContent className="flex flex-col gap-2.5 @[11rem]/inspector:m-1 @[18rem]/inspector:px-2">
          <div className="font-bold">Active shares</div>
          <div className="border-grey border p-2 text-[10px] dark:border-neutral-700">
            {shares.map((share) => (
              <div key={share.id} className="mb-4 flex flex-col items-center gap-x-1 lg:flex-row">
                <div
                  onClick={() =>
                    openModal('editShare', 'editShare', {
                      id: share.id,
                      nodeId,
                    })
                  }
                  className="flex w-2/3 cursor-pointer flex-col"
                >
                  {share.sharedWith && <div className="break-all">{share.sharedWith}</div>}
                  <div className="break-all">{`${baseShareUrl}/${share.id}`}</div>
                  {/* <div>Variants:</div> */}
                  {/* <div className="flex flex-wrap items-center gap-1">
                    <Badge
                      key={1}
                      className="whitespace-nowrap rounded-full bg-neutral-200 px-2 text-[10px]"
                      variant="secondary"
                    >
                      Preview
                    </Badge>
                    <Badge
                      key={2}
                      className=" whitespace-nowrap rounded-full bg-neutral-200 px-2 text-[10px]"
                      variant="secondary"
                    >
                      Preview (watermarked)
                    </Badge>
                  </div> */}
                  <div>Expires: {share.expiresAt ? format(new Date(share.expiresAt), 'yyyy-MM-dd') : 'Never'}</div>
                </div>
                <div className="flex gap-x-2">
                  <Link
                    onClick={() => copy(`${baseShareHref}/${share.id}`)}
                    className="size-5 cursor-pointer text-neutral-700"
                  />
                  <Trash2Icon
                    onClick={() =>
                      openModal(`deleteShareConfirmation`, 'delete', {
                        id: share.id,
                        nodeId,
                      })
                    }
                    className="size-5 cursor-pointer text-neutral-700"
                  />
                </div>
              </div>
            ))}
            <div
              onClick={() =>
                openModal('createShare', 'createShare', {
                  id: nodeId,
                })
              }
              className="m-[-0.5rem] cursor-pointer bg-neutral-200 p-1 dark:border-neutral-700 dark:bg-neutral-800"
            >
              <Plus className="size-6 text-neutral-400" />
            </div>
          </div>
        </AccordionContent>
      ) : (
        <AccordionContent
          onClick={() =>
            openModal('createShare', 'createShare', {
              id: nodeId,
            })
          }
          className="cursor-pointer py-8 text-center text-neutral-600"
        >
          <Share2Icon className="mx-auto mb-2" size={28} strokeWidth={1.5} />
          No shares. Click here to add one
        </AccordionContent>
      )}
      <CreateShareDialog />
      <EditShareDialog mode={dialogMode} />
      <DeleteShareAlertDialog />
    </AccordionItem>
  );
};
