import { FC, Fragment, PropsWithChildren } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useDialog } from '@/context/DialogContext';
import { ulid } from 'ulid';
import { Separator } from './ui/separator';

const ShortcutButton: FC<PropsWithChildren<{ label: string; keys: Array<string> }>> = ({ label, keys }) => {
  return (
    <div className="flex w-full items-center">
      <div className="flex-1 text-sm">{label}</div>
      <div className="flex gap-1.5">
        {keys.map((tooltip) =>
          ['⌘', '⇧', '⌥', '⌃'].includes(tooltip) ? (
            <TooltipProvider key={ulid()} delayDuration={100}>
              <Tooltip>
                <TooltipTrigger className="flex size-7 items-center justify-center rounded-md border bg-transparent text-sm font-medium text-neutral-400 shadow-sm transition duration-150 focus-within:ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2 focus-visible:duration-150 dark:border-neutral-800 dark:focus-within:ring-offset-neutral-950 dark:focus-visible:ring-neutral-500">
                  {tooltip}
                </TooltipTrigger>
                <TooltipContent>
                  <p>{tooltip === '⌘' ? 'Command' : tooltip === '⇧' ? 'Shift' : tooltip === '⌥' ? 'Alt' : 'Control'}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <div
              key={ulid()}
              className="flex size-7 items-center justify-center rounded-md border bg-transparent text-sm font-medium text-neutral-400 shadow-sm transition duration-150 focus-within:ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2 focus-visible:duration-150 dark:border-neutral-800 dark:focus-within:ring-offset-neutral-950 dark:focus-visible:ring-neutral-500"
            >
              {tooltip}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export const KeyboardShortcuts: FC = () => {
  const { openDialogId, setOpenDialogId } = useDialog();

  const shortcuts = [
    [
      { label: 'New Upload', keys: ['⌘', 'T'] },
      { label: 'Print', keys: ['⌘', 'P'] },
    ],
    [
      { label: 'Redo', keys: ['⌘', 'Z'] },
      { label: 'Undo', keys: ['⇧', '⌘', 'Z'] },
      { label: 'Select All', keys: ['⌘', 'A'] },
    ],
    [
      { label: 'List View', keys: ['⇧', '⌥', 'L'] },
      { label: 'Tile View', keys: ['⇧', '⌥', 'T'] },
    ],
    [
      { label: 'Show Sidebar', keys: ['⌘', '['] },
      { label: 'Show Inspector', keys: ['⌘', ']'] },
      { label: 'Theme Toggle', keys: ['⌃', 'T'] },
    ],
    [{ label: 'Show Hotkeys', keys: ['⌘', 'K'] }],
  ];

  return (
    <Dialog open={openDialogId === 'keyboardShortcuts'} onOpenChange={() => setOpenDialogId(null)}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Keyboard Shortcuts</DialogTitle>
          <DialogDescription>List of all available shortcuts in the app.</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col items-center space-y-2">
          {shortcuts.map((group, index) => (
            <Fragment key={ulid()}>
              {index > 0 && <Separator />}
              {group.map(({ label, keys }) => (
                <ShortcutButton label={label} key={ulid()} keys={keys} />
              ))}
            </Fragment>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
