import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getDestinationList } from '@/services/destination.service';
import { useQuery } from '@tanstack/react-query';

export const useDestinations = (options: object) => {
  const destinationListQueryFn = useAuthenticatedQueryFn(getDestinationList);

  const {
    data: destinationList = [],
    isPending: destinationListIsPending,
    isFetching: destinationListIsFetching,
    error: destinationListError,
    refetch: refetchDestinationList,
  } = useQuery({
    queryKey: ['destinationData'],
    queryFn: () => destinationListQueryFn(''),
    ...options,
  });

  return {
    destinationList,
    destinationListIsFetching,
    destinationListIsPending,
    destinationListError,
    refetchDestinationList,
  };
};
