import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getWorkflow, getWorkflowList } from '@/services/workflow.service';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useWorkflow = (options: object) => {
  const workflowQueryFn = useAuthenticatedQueryFn(getWorkflow);

  const {
    data: workflows = [],
    isPending: workflowIsPending,
    isFetching: workflowIsFetching,
    error: workflowError,
    refetch: refetchworkflow,
  } = useQuery({
    queryKey: ['workflow'],
    queryFn: () => workflowQueryFn(null),
    ...options,
  });

  return {
    workflows,
    workflowIsFetching,
    workflowIsPending,
    workflowError,
    refetchworkflow,
  };
};

export const useWorkflowList = (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const workflowListQueryFn = useAuthenticatedQueryFn(getWorkflowList);

  const {
    data: workflowList = {
      workflows: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: workflowListIsPending,
    isFetching: workflowListIsFetching,
    error: workflowListError,
    refetch: refetchWorkflowList,
  } = useQuery({
    queryKey: ['workflowList', params.pagination, params.queryString, params.sort],
    queryFn: () => workflowListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    workflowList,
    workflowListIsFetching,
    workflowListIsPending,
    workflowListError,
    refetchWorkflowList,
  };
};
