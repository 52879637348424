import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getAssetAuditLog } from '@/services/asset.service';
import { getFolderAuditLog } from '@/services/folder.service';
import { getAlbumAuditLog } from '@/services/album.service';
import { getUserAuditLog } from '@/services/users.service';
import { NodeType } from '@/types/tree';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { EntityType } from '@/types/entity';

export const useAudit = (page: number, resultsPerPage: number, id?: string, nodeType?: string, options?: object) => {
  const assetAuditQueryFn = useAuthenticatedQueryFn(getAssetAuditLog);
  const folderAuditQueryFn = useAuthenticatedQueryFn(getFolderAuditLog);
  const albumAuditQueryFn = useAuthenticatedQueryFn(getAlbumAuditLog);
  const userAuditQueryFn = useAuthenticatedQueryFn(getUserAuditLog);
  const pagination = { limit: Number(resultsPerPage), offset: (Number(page ?? 1) - 1) * resultsPerPage };

  const {
    data: audits,
    isPending: auditsArePending,
    isFetching: auditsAreFetching,
    error: auditError,
    refetch: refetchAudits,
  } = useQuery({
    queryKey: ['auditData', { ...pagination, id }],
    queryFn: () => {
      if (nodeType === NodeType.Folders) {
        return folderAuditQueryFn({ pagination, id });
      } else if (nodeType === NodeType.Albums) {
        return albumAuditQueryFn({ pagination, id });
      } else if (nodeType === EntityType.User) {
        return userAuditQueryFn({ pagination, id });
      } else {
        return assetAuditQueryFn({ pagination, id });
      }
    },
    placeholderData: keepPreviousData,
    ...options,
  });

  return { audits, auditsAreFetching, auditsArePending, auditError, refetchAudits };
};
