import { FormField, FormItem, FormLabel, FormMessage, FormControl, FormDescription } from '@/components/ui/form';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipPortal } from '@/components/ui/tooltip';
import { MetadataSelect } from '@/components/inspector/metadata-accordion/metadata-select';
import { MetadataSwitch } from '@/components/inspector/metadata-accordion/metadata-switch';
import { MetadataField } from '@/types/metadata';
import { ComponentType } from 'react';
import { MultiSelect } from '@/components/inspector/metadata-accordion/metadata-multiselect';
import { DatePicker } from '@/components/ui/datepicker';
import { Separator } from '@/components/ui/separator';
import { Textarea } from '@/components/ui/textarea';
import { DateTime } from 'luxon';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { Info, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ulid } from 'ulid';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export type MetadataTypeMap = {
  string: string;
  text: string;
  integer: number;
  float: number;
  boolean: boolean;
  date: Date;
  datetime: DateTime;
  select: string;
  multi_select: Array<string>;
  options: object;
};

const FieldComponents: Record<keyof MetadataTypeMap, ComponentType<any>> = {
  string: Input,
  text: Textarea,
  integer: Input,
  float: Input,
  boolean: MetadataSwitch,
  date: DatePicker,
  datetime: DatePicker,
  select: MetadataSelect,
  multi_select: MultiSelect,
  options: Options,
};

export const renderField = (
  form: any,
  field: Pick<MetadataField, 'name' | 'slug' | 'type' | 'description' | 'options'> & {
    'disabled': boolean;
    'placeholder'?: string;
    'validation'?: z.ZodTypeAny;
    'min'?: number;
    'data-cy'?: string;
  },
  separatorClass?: string,
): JSX.Element | null => {
  const Component = FieldComponents[field.type as keyof MetadataTypeMap];

  if (!Component) {
    return null;
  }

  const isRequired = field.type === 'boolean' ? false : field.validation ? !field.validation.isOptional() : false;

  return (
    <FormField
      key={field.slug}
      name={field.slug}
      control={form.control}
      render={({ field: controllerField }) => (
        <>
          <FormItem
            className={cn(
              'flex @[30rem]/inspector:gap-x-3',
              field.type === 'boolean'
                ? 'flex-col gap-3 @[24rem]/inspector:flex-row @[24rem]/inspector:items-center'
                : 'flex-col @[30rem]/inspector:flex-row',
            )}
          >
            <div
              className={cn(
                'flex items-start gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end',
                field.type === 'boolean' && 'order-2 @[30rem]/inspector:order-1',
              )}
            >
              <FormLabel
                htmlFor={field.slug}
                className={cn(
                  'mx-2 leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:text-right',
                  field.type === 'boolean' ? '@[30rem]/inspector:mr-1.5' : '@[30rem]/inspector:mt-2',
                  isRequired && 'after:ml-0.5 after:text-red-500 after:content-["*"]',
                )}
              >
                {field.name}
              </FormLabel>
              {field.description && (
                <TooltipProvider key={ulid()} delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger
                      asChild
                      className={cn(
                        'hidden @[24rem]/inspector:flex @[30rem]/inspector:order-1',
                        field.type !== 'boolean' && '@[30rem]/inspector:mt-2',
                      )}
                    >
                      <Info className="size-3.5 text-neutral-600" />
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent className="max-w-[300px]">
                        <p>{field.description}</p>
                      </TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <FormControl
              className={cn(
                field.type === 'boolean'
                  ? 'order-2 !mt-1 @[24rem]/inspector:order-1'
                  : '@[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2',
              )}
            >
              <div className="relative">
                <Component
                  {...controllerField}
                  placeholder={
                    field.type === 'date' ? 'YYYY-MM-DD' : field.placeholder ? field.placeholder : field.name
                  }
                  options={field.options}
                  onChange={(value: any) => {
                    if (field.type === 'multi_select') {
                      const newValues = (value as Array<{ value: string; label: string }>).map(
                        (option) => option.value,
                      );
                      controllerField.onChange(newValues);
                    } else {
                      controllerField.onChange(value);
                    }
                  }}
                  {...(field.type === 'float' && { type: 'number' })}
                  {...(field.min !== undefined && { min: field.min })}
                  disabled={field.disabled}
                  data-cy={field['data-cy'] ? field['data-cy'] : `${field.slug}-field`}
                  className={cn(
                    field.type === 'text' && 'w-full',
                    ['text', 'string', 'float', 'integer', 'select', 'multi_select', 'date'].includes(field.type) &&
                      '@[30rem]/inspector:!mt-0',
                    field.type === 'boolean' && 'ml-3 @[30rem]/inspector:ml-0 @[30rem]/inspector:mt-0.5',
                    ['text', 'string', 'float', 'integer', 'select', 'date'].includes(field.type) &&
                      'bg-white focus-within:ring-offset-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950',
                    field.type === 'date' &&
                      'bg-white focus-within:ring-offset-neutral-100 hover:bg-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950 dark:hover:bg-neutral-950',
                    ['text', 'string', 'float', 'integer', 'date'].includes(field.type) && '!pr-0',
                    form.formState.errors[field.slug] &&
                      ['text', 'string', 'float', 'integer', 'date'].includes(field.type) &&
                      '!border-red-500 dark:focus-visible:ring-red-500 ',
                  )}
                  // Include Input's `appendIcon` property with an "X" button only if field type is
                  // text, string, float, date or integer and if controllerField.value contains value
                  // and if the field is not disabled
                  {...(['text', 'string', 'float', 'integer', 'date'].includes(field.type) &&
                    controllerField.value &&
                    !field.disabled && {
                      appendIcon: (
                        <Button
                          onClick={() => controllerField.onChange('')}
                          variant="ghost"
                          className="mr-px px-3 !text-neutral-400 duration-150 hover:bg-transparent hover:!text-red-300 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-red-800  dark:focus-visible:ring-neutral-900"
                          aria-label="Clear field"
                        >
                          <X className="size-3" />
                        </Button>
                      ),
                    })}
                />
                <FormMessage />
              </div>
            </FormControl>
            {field.description && (
              <FormDescription
                className={cn('mx-2 block @[24rem]/inspector:hidden', field.type === 'boolean' && 'order-3')}
              >
                {field.description}
              </FormDescription>
            )}
          </FormItem>
          <Separator className={separatorClass} />
        </>
      )}
    />
  );
};
