import { ReactNode, useCallback, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { uniq, sortBy } from 'lodash';
import { useTree } from '@/hooks/data/tree/useTree';
import { ChevronRightIcon, DocumentArrowUpIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { useDialog } from '@/context/DialogContext';
import { FolderPlus, BookImage, FolderLock, FolderOpen, LucideFolder, Pen, Trash2Icon } from 'lucide-react';
import { useRouter } from 'next/router';
import { useDrag, useDrop } from 'react-dnd';
import { DragItem, DraggableItems } from '@/types/dragDrop';
import { TreeRecursiveChildrenNode } from '@/types/tree';
import { NodeType } from '@/types/tree';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';
import { useCommandContext } from '@/context/CommandContext';
import { MoveNodeCommand } from '@/hooks/commands/folder/MoveNodeCommand';
import { AddAssetCommand } from '@/hooks/commands/albums/AddAssetCommand';
import { useToast } from '@/components/ui/use-toast';
import { styles, hoverStyles, dropStyle, selectedStyles } from '@/components/sidebar/sidebar';
// import { Badge } from '@/components/ui/badge';
import { ToastAction } from '@/components/ui/toast';

type TreeProps = {
  className?: ReactNode;
};

export function Tree({ className }: TreeProps) {
  const { tree, openIds, setOpenIds } = useTree();

  const toggleNodeHandler = (clickedId: string) => {
    const isOpen = openIds.includes(clickedId);
    const updatedIds = uniq(isOpen ? openIds.filter((id) => id !== clickedId) : [...openIds, clickedId]);
    setOpenIds(updatedIds);
  };

  return (
    <ul className={cn('tree group/tree mt-px flex flex-col', className)} data-testid="tree">
      <Branch tree={tree} openIds={openIds} toggleNodeHandler={toggleNodeHandler} parentId={undefined} depth={0} />
    </ul>
  );
}

type BranchProps = {
  tree: Array<TreeRecursiveChildrenNode>;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
};

export function Branch({ tree, openIds, toggleNodeHandler, parentId, depth }: BranchProps) {
  const sortedTree = sortBy(tree, (treeItem) => treeItem.node.name.toLowerCase());

  return sortedTree?.map((treeBranch) => {
    const { id, type } = treeBranch;
    switch (type) {
      case 'folder':
        return (
          <Folder
            key={id}
            treeBranch={treeBranch}
            openIds={openIds}
            toggleNodeHandler={toggleNodeHandler}
            parentId={parentId}
            depth={depth}
          />
        );
      case 'album':
        return <Album key={id} treeBranch={treeBranch} parentId={parentId} depth={depth} />;
      default:
        return null;
    }
  });
}

type FolderProps = {
  treeBranch: TreeRecursiveChildrenNode;
  openIds: Array<string>;
  toggleNodeHandler: (id: string) => void;
  parentId?: string;
  depth: number;
};

export function Folder({ treeBranch, openIds, toggleNodeHandler, parentId, depth }: FolderProps) {
  const { openModal } = useDialog();
  const { setUpdatedIds } = useTree();

  const { id, node, children = [], with_children: withChildren } = treeBranch;
  const { name, locked = false, id: folderId } = node;
  const { query, push } = useRouter();
  const { selectedNodes, setSelectedNodes, setCurrentSelectedType, selectedFolder, moveNodes } = useTree();
  const { deselectAllAssets } = useAssetSelection();
  const { apply } = useCommandContext();
  const { toast } = useToast();

  const isOpen = openIds.includes(id);

  const sortedChildren = sortBy(children, 'node.name');

  const onSelectFolder = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Folders);
    void push({
      pathname: '/',
      query: { ...query, album: undefined, folder: folderId, page: 1 },
    });
  }, [folderId, id, push, query, setCurrentSelectedType, setSelectedNodes, deselectAllAssets, name]);

  // drag'n drop configuration
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DraggableItems.FOLDER, DraggableItems.ASSET, DraggableItems.ALBUM],
      canDrop: () => true,
      drop: (item: DragItem) => {
        let assetIds = [item.nodeId];
        if (item.type === DraggableItems.ASSET && selectedNodes?.length > 1) {
          assetIds = selectedNodes.map((asset) => asset.id);
        }

        const moveNodeCommand = MoveNodeCommand(moveNodes, id, item.currentParent, assetIds);
        apply(moveNodeCommand);
        if (item.type === DraggableItems.ASSET) {
          toast({
            title: 'Asset(s) moved',
            description: `${selectedNodes.length} asset(s) have been moved successfully.`,
          });
          setSelectedNodes([]);
        }

        setUpdatedIds([id]);
      },
      collect: (monitor) => ({
        isOver: Boolean(monitor.isOver()),
        canDrop: Boolean(monitor.canDrop()),
      }),
    }),
    [selectedNodes, id, DraggableItems],
  );

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    canDrag: !locked,
    type: DraggableItems.FOLDER,
    item: { type: DraggableItems.FOLDER, nodeId: id, id: folderId, currentParent: parentId } as DragItem,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  }));

  // select folder when dragging
  useEffect(() => {
    if (isDragging && selectedFolder !== folderId) {
      onSelectFolder();
    }
  }, [folderId, isDragging, onSelectFolder, selectedFolder]);

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1" ref={(ref) => drag(preview(ref))}>
        <li className="mt-px flex flex-col" title={name}>
          <div
            ref={drop}
            style={{ paddingLeft: `${depth * 15}px` }}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              !isDragging && hoverStyles,
              selectedFolder === folderId && selectedStyles,
              isOver && canDrop && dropStyle,
            )}
          >
            {withChildren && (
              <div onClick={() => toggleNodeHandler(id)} className="group/chevron mr-px cursor-pointer select-none p-1">
                <div className="size-4 rounded duration-150 group-hover/chevron:bg-black/10 dark:group-hover/chevron:bg-white/10">
                  <ChevronRightIcon
                    className={cn('size-4 p-0.5 duration-150', {
                      'rotate-90': isOpen,
                    })}
                    strokeWidth={2}
                  />
                </div>
              </div>
            )}
            <button
              className={cn('flex w-full items-center justify-start py-1 outline-none', {
                'ml-6': !withChildren,
              })}
              onClick={onSelectFolder}
              onContextMenu={onSelectFolder}
            >
              {locked ? (
                <FolderLock
                  className="mr-2 size-4 min-w-4 stroke-neutral-500 dark:stroke-neutral-400"
                  strokeWidth={2}
                />
              ) : isOpen ? (
                <FolderOpen className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              ) : (
                <LucideFolder className="mr-2 size-4 min-w-4 stroke-violet-500" strokeWidth={2} />
              )}
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === folderId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
            </button>
          </div>
          {isOpen && (
            <ul className="relative flex flex-col">
              <div
                className="absolute inset-y-1 z-20 ml-3 block w-px bg-neutral-600/10 opacity-0 transition-opacity duration-300 group-hover/tree:opacity-100 dark:bg-neutral-200/10"
                style={{ left: `${depth * 15}px` }}
              />
              <Branch
                tree={sortedChildren}
                openIds={openIds}
                toggleNodeHandler={toggleNodeHandler}
                parentId={id}
                depth={depth + 1}
              />
            </ul>
          )}
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('uploadAsset');
            }
          }}
        >
          <DocumentArrowUpIcon className="mr-2 size-4" />
          New Upload
        </ContextMenuItem>

        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createFolder', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Folder
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            if (selectedFolder) {
              openModal('editCreateFolderAlbum', 'createAlbum', { id: selectedFolder });
            }
          }}
        >
          <FolderPlus className="mr-2 size-4" />
          New Album
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          disabled={locked}
          onClick={() => openModal('editCreateFolderAlbum', 'renameFolder', { id: folderId })}
        >
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectFolder();
            openModal('deleteConfirmation');
          }}
          className="!text-red-600 focus:!bg-red-100/50 dark:focus:!bg-red-950/50"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}

type AlbumProps = {
  treeBranch: TreeRecursiveChildrenNode;
  parentId?: string;
  depth: number;
};

export function Album({ treeBranch, parentId, depth }: AlbumProps) {
  const { openModal } = useDialog();
  const { id, node } = treeBranch;
  const { name, id: albumId } = node;
  const { query, push } = useRouter();
  const { setSelectedNodes, setCurrentSelectedType, selectedAlbum } = useTree();
  const { selectedAssetIds, deselectAllAssets } = useAssetSelection();
  //const addAssetCommand = AddAssetCommand();
  const { addAssets, removeAssets } = useAlbumAssets();
  const { apply } = useCommandContext();
  const { toast } = useToast();

  const onSelectAlbum = useCallback(() => {
    deselectAllAssets();
    setSelectedNodes([{ id, name }]);
    setCurrentSelectedType(NodeType.Albums);

    void push({
      pathname: '/',
      query: { ...query, folder: undefined, album: albumId, page: 1 },
    });
  }, [id, node.id, push, query, selectedAlbum, setCurrentSelectedType, setSelectedNodes]);

  // drag'n drop configuration
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [DraggableItems.ASSET],
      canDrop: () => true,
      drop: async (item: DragItem) => {
        let assetIds = [item.id];
        if (item.type === DraggableItems.ASSET && selectedAssetIds?.length > 1) {
          assetIds = selectedAssetIds.map((asset) => asset.id);
        }
        const addAssetCommand = AddAssetCommand(addAssets, removeAssets, node.id, assetIds);
        const response = await apply(addAssetCommand);
        const assetsAdded = response.add ?? assetIds;
        const wereAssetsAdded = assetsAdded.length > 0;
        if (item.type === DraggableItems.ASSET) {
          toast({
            title: wereAssetsAdded ? 'Asset(s) added' : 'No asset has been added',
            description: wereAssetsAdded
              ? `${assetsAdded.length} asset(s) have been added successfully.`
              : 'The album already contains the asset(s)',
            action: wereAssetsAdded ? (
              <ToastAction
                onClick={() => removeAssets({ albumId: node.id, assetIds: assetsAdded })}
                altText="Undo add asset(s)"
              >
                Undo
                <ArrowUturnLeftIcon className="ml-1 size-3" />
              </ToastAction>
            ) : undefined,
          });
          deselectAllAssets();
        }
      },
      collect: (monitor) => ({
        isOver: Boolean(monitor.isOver()),
        canDrop: Boolean(monitor.canDrop()),
      }),
    }),
    [selectedAssetIds],
  );

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: DraggableItems.FOLDER,
    item: { type: DraggableItems.ALBUM, nodeId: id, currentParent: parentId } as DragItem,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  }));

  useEffect(() => {
    if (isDragging && selectedAlbum !== node.id) {
      onSelectAlbum();
    }
  }, [isDragging, node.id, onSelectAlbum, selectedAlbum]);

  return (
    <ContextMenu>
      <ContextMenuTrigger className="flex-1">
        <li key={id} className="mt-px flex flex-col" ref={(ref) => drag(drop(preview(ref)))} title={name}>
          <div
            style={{ paddingLeft: `${depth * 15}px` }}
            className={cn(
              'flex w-full items-center rounded-md pr-1.5',
              styles,
              hoverStyles,
              selectedAlbum === node.id && selectedStyles,
              isOver && canDrop && dropStyle,
            )}
          >
            <button
              onClick={() => {
                onSelectAlbum();
              }}
              onContextMenu={() => {
                onSelectAlbum();
              }}
              className={cn('flex w-full items-center justify-start py-1 pl-6 outline-none')}
            >
              <BookImage className="mr-2 inline-block size-4 min-w-4 stroke-teal-600" strokeWidth={2} />
              <div className="flex w-full justify-between text-sm">
                <span className="line-clamp-1 break-all text-left">{name}</span>
                {/* placeholder for items counter */}
                {/* <Badge
                  className={cn(
                    'ml-2 whitespace-nowrap rounded-full px-2 py-0 text-[11px]',
                    selectedFolder === folderId
                      ? 'bg-neutral-100 dark:bg-neutral-900'
                      : 'bg-neutral-200 dark:bg-neutral-800',
                  )}
                  variant="secondary"
                >
                  {items.count}
                </Badge> */}
              </div>
            </button>
          </div>
        </li>
      </ContextMenuTrigger>
      <ContextMenuContent>
        <ContextMenuItem onClick={() => openModal('editCreateFolderAlbum', 'renameAlbum', { id: node.id })}>
          <Pen className="mr-2 size-4" />
          Rename
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => {
            onSelectAlbum();
            openModal('deleteConfirmation');
          }}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
}
