import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getMetadata, getMetadataList } from '@/services/metadata.service';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useMetadataList = (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const metadataListQueryFn = useAuthenticatedQueryFn(getMetadataList);

  const {
    data: metadataList = {
      metadata: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: metadataListIsPending,
    isFetching: metadataListIsFetching,
    error: metadataListError,
    refetch: refetchMetadataList,
  } = useQuery({
    queryKey: ['metadataList', params.pagination, params.queryString, params.sort],
    queryFn: () => metadataListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    metadataList,
    metadataListIsFetching,
    metadataListIsPending,
    metadataListError,
    refetchMetadataList,
  };
};

export const useMetadata = (id: string) => {
  const metadataQueryFn = useAuthenticatedQueryFn(getMetadata);

  const {
    data: metadata,
    isPending: metadataIsPending,
    isFetching: metadataIsFetching,
    error: metadataError,
    refetch: refetchMetadata,
  } = useQuery({
    queryKey: ['metadata', id],
    queryFn: () => metadataQueryFn({ id }),
  });

  return {
    metadata,
    metadataIsFetching,
    metadataIsPending,
    metadataError,
    refetchMetadata,
  };
};
