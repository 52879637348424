export const MoveNodeCommand = (
  moveNodes: any,
  parentFolderNodeId: string,
  oldParentFolderNodeId: string,
  nodes: Array<string>,
) => {
  const apply = (): void => {
    void moveNodes([{ parentFolderNodeId, nodes }]);
  };

  const undo = (): void => {
    void moveNodes([{ parentFolderNodeId: oldParentFolderNodeId, nodes }]);
  };

  const redo = (): void => {
    void apply();
  };

  return {
    apply,
    undo,
    redo,
  };
};
