import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { useDialog } from '@/context/DialogContext';
import { useTrashSelection } from '@/context/TrashSelectionContext';
import { ToastAction } from '@/components/ui/toast';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { RestoreTrashCommand } from '@/hooks/commands/trash/RestoreTrashCommand';
import { useCommandContext } from '@/context/CommandContext';
import { useQueryClient } from '@tanstack/react-query';

export const RestoreTrashAlertDialog: FC = () => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId } = useDialog();
  const { selectedTrashIds, deselectAllTrash } = useTrashSelection();
  const { apply, undo } = useCommandContext();

  const restoreTrashCommand = RestoreTrashCommand(selectedTrashIds);
  const queryClient = useQueryClient();

  return (
    <AlertDialog open={openDialogId === 'restoreTrashConfirmation'} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>This will restore selected items from Trash</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-green-500 hover:bg-green-600"
            onClick={() =>
              apply(restoreTrashCommand).then(() => {
                void queryClient.invalidateQueries({ queryKey: ['trash'] });

                deselectAllTrash();
                toast({
                  title: 'Items Restored',
                  description: 'Trash items has been successfully restored',
                  action: (
                    <ToastAction onClick={undo} altText="Undo remove asset(s)">
                      Undo
                      <ArrowUturnLeftIcon className="ml-1 size-3" />
                    </ToastAction>
                  ),
                });
              })
            }
          >
            Restore
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
