import { isValidElement, Children, cloneElement } from 'react';
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Sidebar } from '@/components/sidebar/sidebar';
import { ScrollArea } from '@/components/ui/scroll-area';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { Drawer, DrawerContent } from '@/components/ui/drawer';
import { Inspector } from '@/components/inspector/inspector';
import { Navigation } from './navigation';
import { useUserSettings } from '@/context/UserSettingsContext';
import { GetAssets } from '@/types/asset';
import { GetTrashResponse } from '@/types/trash';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { GalleryLayoutProps } from './gallery-layout';
import { TrashLayoutProps } from './trash-layout';

export const DamLayout = ({
  children,
  assets,
  trash,
  showAssetSizeSlider = false,
  showAssetsPerPageMenu = false,
  showGalleryLayoutOptions = false,
}: PropsWithChildren<{
  assets?: GetAssets;
  trash?: GetTrashResponse;
  showAssetSizeSlider?: boolean;
  showAssetsPerPageMenu?: boolean;
  showGalleryLayoutOptions?: boolean;
}>) => {
  const [resizeValue, setResizeValue] = useState(0);

  const handleResize = (resize: number) => {
    setResizeValue(resize);
  };
  const { isMobile } = useBreakpoint();
  const { showSidebar, setShowSidebar, showInspector, setShowInspector } = useUserSettings();

  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (event.metaKey && event.code === 'BracketLeft') {
        event.preventDefault();
        setShowSidebar((prev) => !prev);
      }
      if (event.metaKey && event.code === 'BracketRight') {
        event.preventDefault();
        setShowInspector((prev) => !prev);
      }
    };

    if (!isMobile) {
      document.addEventListener('keydown', callback);
      return () => {
        document.removeEventListener('keydown', callback);
      };
    }
  }, [isMobile, setShowSidebar, setShowInspector]);

  return isMobile ? (
    <>
      <Drawer open={showSidebar} onOpenChange={setShowSidebar} shouldScaleBackground>
        <DrawerContent className="h-[calc(100dvh-30px)]">{<Sidebar />}</DrawerContent>
      </Drawer>
      <ScrollArea className="relative h-full">
        <Navigation data={assets} trash={trash} showGalleryLayoutOptions={showGalleryLayoutOptions} />
        {children}
      </ScrollArea>
      <Drawer open={showInspector} onOpenChange={setShowInspector}>
        <DrawerContent className="h-[calc(100dvh-30px)] bg-neutral-100 dark:bg-neutral-900">
          <Inspector data={assets?.assets ?? []} />
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <div className="flex min-h-screen flex-col">
      <ResizablePanelGroup autoSaveId="mainLayout" direction="horizontal">
        {showSidebar && (
          <>
            <ResizablePanel
              order={1}
              defaultSize={15}
              minSize={10}
              collapsedSize={5}
              className="bg-neutral-300 dark:bg-black"
            >
              {<Sidebar />}
            </ResizablePanel>
            <ResizableHandle withHandle />
          </>
        )}
        <ResizablePanel
          order={2}
          minSize={30}
          maxSize={90}
          className="relative flex max-h-screen flex-col bg-neutral-100 dark:bg-[#111111]"
          onResize={handleResize}
        >
          <div className="relative h-screen @container/main">
            <Navigation
              data={assets}
              trash={trash}
              showAssetSizeSlider={showAssetSizeSlider}
              showAssetsPerPageMenu={showAssetsPerPageMenu}
              showGalleryLayoutOptions={showGalleryLayoutOptions}
            />
            {Children.map(children, (child) =>
              isValidElement(child)
                ? cloneElement(child as ReactElement<GalleryLayoutProps | TrashLayoutProps>, {
                    resizeValue,
                  })
                : child,
            )}
          </div>
        </ResizablePanel>
        {showInspector && (
          <>
            <ResizableHandle withHandle />
            <ResizablePanel order={3} defaultSize={20} minSize={20} className="bg-white dark:bg-black">
              <Inspector data={assets?.assets ?? []} />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </div>
  );
};
