import Link from 'next/link';
import { FC } from 'react';
import { useRouter } from 'next/router';
import { useAssetSelection } from '@/context/AssetSelectionContext';

export const Logo: FC<{ className?: string }> = ({ className }) => {
  const {
    query: { page, results },
  } = useRouter();
  const { deselectAllAssets } = useAssetSelection();

  return (
    <Link
      className={className}
      onClick={deselectAllAssets}
      href={
        // We aim to retain the 'assets per page' count if accessible, guiding the user to the initial page, otherwise, direct them to '/'
        results
          ? {
              pathname: '/',
              query: {
                page: 1,
                results: results && page ? results : 50,
              },
            }
          : '/'
      }
    >
      <h2 className="text-2xl font-bold leading-7 tracking-tight text-black dark:text-white">DAM</h2>
    </Link>
  );
};
