import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { SortOrderOptions, SortOrderValues } from '@/types/sort';
import { getAutocompleteTags, getTag, getTagList } from '@/services/tags.service';

export const useAutocompleteTags = (q?: any, options?: object) => {
  const getAutocompleteTagsFn = useAuthenticatedQueryFn(getAutocompleteTags);

  const {
    data: tags,
    isLoading: areTagsLoading,
    isPending: areTagsPending,
    isFetching: areTagsFetching,
    error: tagsError,
    refetch: refetchTags,
  } = useQuery({
    queryKey: ['tags', q],
    queryFn: () => getAutocompleteTagsFn(q),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    tags,
    refetchTags,
    areTagsFetching,
    areTagsLoading,
    areTagsPending,
    tagsError,
  };
};

export const useTagList = (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const tagListQueryFn = useAuthenticatedQueryFn(getTagList);

  const {
    data: tagList = {
      tags: [],
      pagination: { count: 0, limit: 0, offset: 0 },
      sort: { order: SortOrderOptions.ASC, value: SortOrderValues.CREATED_AT },
    },
    isPending: tagListIsPending,
    isFetching: tagListIsFetching,
    error: tagListError,
    refetch: refetchTagList,
  } = useQuery({
    queryKey: ['tagList', params.pagination, params.queryString, params.sort],
    queryFn: () => tagListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    tagList,
    tagListIsFetching,
    tagListIsPending,
    refetchTagList,
    tagListError,
  };
};

export const useTag = (id: string, options: object, form: any) => {
  const getTagQueryFn = useAuthenticatedQueryFn(getTag);

  const {
    data: tag = [],
    isPending: tagIsPending,
    isFetching: tagIsFetching,
    error: tagError,
    refetch: refetchTag,
  } = useQuery({
    queryKey: ['getTag', id],
    queryFn: async () => {
      const data = await getTagQueryFn({ asset_id: id });
      form?.reset(data);
      return data;
    },
    ...options,
  });

  return {
    tag,
    tagIsPending,
    refetchTag,
    tagIsFetching,
    tagError,
  };
};
