const events: Record<string, string> = {
  'asset.created': 'created a new asset',
  'asset.updated': 'updated an asset',
  'asset.deleted': 'deleted an asset',
  'asset.moved': 'moved an asset',
  'asset.restored': 'restored an asset',
  'asset.trashed': 'trashed an asset',
  'asset.undeleted': 'undeleted an asset',
  'asset.note.added': 'added note to an asset',
  'asset.note.deleted': 'deleted asset note',
  'asset.note.undeleted': 'undeleted asset note',
  'asset.note.updated': 'updated asset note',
  'asset.version.activated': 'activated version of asset',
  'asset.version.created': 'created a new version of asset',
  'asset.version.deleted': 'deleted version of asset',
  'album.assets.added': 'added assets into album',
  'album.assets.reordered': 'reordered assets into album',
  'album.assets.removed': 'removed assets from album',
  'album.created': 'created an album',
  'album.updated': 'updated an album',
  'album.deleted': 'deleted an album',
  'album.moved': 'moved an album',
  'album.restored': 'restored an album',
  'album.trashed': 'trashed an album',
  'album.undeleted': 'undeleted an album',
  'folder.created': 'created a folder',
  'folder.updated': 'updated a folder',
  'folder.deleted': 'deleted a folder',
  'folder.moved': 'moved a folder',
  'folder.restored': 'restored a folder',
  'folder.trashed': 'trashed a folder',
  'folder.undeleted': 'undeleted a folder',
  'metadata.created': 'created metadata',
  'metadata.updated': 'updated metadata',
  'metadata.deleted': 'deleted metadata',
  'trash.emptied': 'emptied the trash',
  'version.created': 'created a new version',
  'variant.created': 'created a new variant',
  'variant.updated': 'updated a variant',
  'variant.deleted': 'deleted a variant',
};

export const getEventMessage = (event: string) => events[event];
