import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { ToastAction } from '@/components/ui/toast';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useDialog } from '@/context/DialogContext';
import { useTree } from '@/hooks/data/tree/useTree';
import { useRouter } from 'next/router';
import { useAssetList } from '@/hooks/data/assets/useAssetList';
import { NodeType } from '@/types/tree';
import { RemoveAssetsResponse } from '@/types/album';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { RemoveAssetsCommand } from '@/hooks/commands/albums/RemoveAssetsCommand';
import { TrashNodesCommand } from '@/hooks/commands/trash/TrashNodesCommand';
import _ from 'lodash';

export const DeleteAlertDialog: FC<{ count?: number }> = ({ count }) => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId } = useDialog();
  const {
    selectedNodes,
    currentSelectedType,
    setSelectedNodes,
    setCurrentSelectedType,
    selectedAlbum,
    selectedFolder,
  } = useTree();
  const { selectedAssetIds, deselectAllAssets, setSelectedAssetIds } = useAssetSelection();
  const { query, push } = useRouter();
  const { results, page, folder, album } = query;

  const getPageNumber = (
    totalCount: number,
    selectedAssets: Array<{ id: string; name: string }>,
    pageNumber: string | Array<string> | undefined,
  ) => {
    return (totalCount - selectedAssets.length) % 10 === 0 ? Number(pageNumber) - 1 : Number(pageNumber ?? 0);
  };

  const { refetch } = useAssetList(
    Number(page ?? 1),
    Number(results ?? 10),
    folder && String(folder),
    album && String(album),
  );
  const { apply, undo } = useCommandContext();

  const removeCommand = RemoveAssetsCommand(
    String(album),
    selectedAssetIds.map(({ id }) => String(id)),
  );
  const trashCommand = TrashNodesCommand(selectedNodes.map(({ id }) => String(id)));

  const isRemove = album && selectedAssetIds?.length;
  const selectedList = isRemove ? selectedAssetIds : selectedNodes;

  const deleteMessage = `The selected ${
    currentSelectedType === NodeType.Folders ? 'folder' : currentSelectedType === NodeType.Albums ? 'album' : 'asset'
  }${selectedAssetIds && selectedAssetIds.length > 1 ? 's' : ''} will be moved to trash`;
  const removeMessage = `The selected asset${
    selectedAssetIds && selectedAssetIds.length > 1 ? 's' : ''
  } will be removed from the album`;
  const message = isRemove ? removeMessage : deleteMessage;

  return (
    <AlertDialog open={openDialogId === 'deleteConfirmation'} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {message}:
            <ul className="py-2">{selectedList?.slice(0, 5).map(({ id, name }) => <li key={id}>{name}</li>)}</ul>
            {selectedList?.length > 5 && <p>and {selectedList.length - 5} more...</p>}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          {isRemove ? (
            <AlertDialogAction
              className="bg-red-500 hover:bg-red-600"
              onClick={() => {
                apply(removeCommand).then((response: RemoveAssetsResponse) => {
                  const removedCount = response?.remove?.length;
                  if (removedCount) {
                    toast({
                      title: 'Asset(s) removed',
                      description: `${removedCount} of ${selectedAssetIds.length} asset(s) have been successfully removed`,
                      action: (
                        <ToastAction onClick={undo} altText="Undo remove asset(s)">
                          Undo
                          <ArrowUturnLeftIcon className="ml-1 size-3" />
                        </ToastAction>
                      ),
                    });

                    // Deselect assets and update inspector to show selected folder/album details
                    deselectAllAssets();
                    setCurrentSelectedType(
                      selectedAlbum ? NodeType.Albums : selectedFolder ? NodeType.Folders : undefined,
                    );
                  }
                });
              }}
            >
              Remove
            </AlertDialogAction>
          ) : (
            <AlertDialogAction
              className="bg-red-500 hover:bg-red-600"
              onClick={() => {
                apply(trashCommand).then(() => {
                  const newQuery = { ...query };
                  let type = 'asset';
                  if (currentSelectedType === NodeType.Folders) {
                    newQuery.folder = undefined;
                    type = 'folder';
                    void push(newQuery);
                  } else if (currentSelectedType === NodeType.Albums) {
                    newQuery.album = undefined;
                    type = 'album';
                    void push(newQuery);
                  } else if (currentSelectedType === NodeType.Assets) {
                    const updatedPage = count && selectedNodes ? getPageNumber(count, selectedNodes, page) : page;
                    void push({
                      query: {
                        ...query,
                        page: Number(updatedPage ?? 1),
                      },
                    });
                    void refetch();
                    type = selectedAssetIds && selectedAssetIds.length > 1 ? 'assets' : 'asset';
                  }

                  setSelectedNodes([]);
                  setSelectedAssetIds([]);
                  setCurrentSelectedType(NodeType.Folders);

                  toast({
                    title: `${_.capitalize(type)} deleted`,
                    description: `Your ${type} has been successfully deleted`,
                    action: (
                      <ToastAction onClick={undo} altText="Undo node delete">
                        Undo
                        <ArrowUturnLeftIcon className="ml-1 size-3" />
                      </ToastAction>
                    ),
                  });
                });
              }}
            >
              Delete
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
