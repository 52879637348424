import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import {
  getAsset,
  createAssetVersion as createAssetVersionQuery,
  createAsset as createAssetQuery,
} from '@/services/asset.service';
import { Asset } from '@/types/asset';
import { DefaultError, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AssetListQueryKeyPrefix } from './useAssetList';

export const useAsset = (
  id?: string,
  form?: {
    reset: (data: any) => void;
  },
  options?: object,
) => {
  const assetsQueryFn = useAuthenticatedQueryFn(getAsset);
  const createAssetFn = useAuthenticatedQueryFn(createAssetQuery);
  const createAssetVersionFn = useAuthenticatedQueryFn(createAssetVersionQuery);

  const queryClient = useQueryClient();

  // queries
  const {
    data: asset,
    isLoading: isAssetLoading,
    isPending: isAssetPending,
    isFetching: isAssetFetching,
    error: assetError,
    refetch: refetchAsset,
  } = useQuery({
    queryKey: ['asset', id],
    queryFn: id
      ? async () => {
          const data = await assetsQueryFn({ id });
          form?.reset(data);
          return data;
        }
      : () => undefined,
    // placeholderData: keepPreviousData,
    enabled: false,
    ...options,
  });

  const {
    mutateAsync: createAsset,
    isPending: isCreateAssetPending,
    error: createAssetError,
  } = useMutation<Asset, DefaultError, Parameters<typeof createAssetVersionQuery>['0']>({
    mutationFn: (params) => createAssetFn(params),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [AssetListQueryKeyPrefix] });
    },
  });

  const {
    mutateAsync: createAssetVersion,
    isPending: isUploadVersionPending,
    error: uploadVersionError,
  } = useMutation<Asset, DefaultError, Parameters<typeof createAssetVersionQuery>['0']>({
    mutationFn: (params) => createAssetVersionFn(params),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['asset'] });
    },
  });

  return {
    asset,
    refetchAsset,
    createAsset,
    createAssetVersion,
    isAssetFetching,
    isAssetLoading,
    isPending: isAssetPending || isUploadVersionPending,
    error: assetError ?? uploadVersionError,
  };
};
