import { FC } from 'react';
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialog,
} from '@/components/ui/alert-dialog';
import { useToast } from '@/components/ui/use-toast';
import { useDialog } from '@/context/DialogContext';
import { useTrash } from '@/hooks/data/trash/useTrash';

export const EmptyTrashAlertDialog: FC = () => {
  const { toast } = useToast();
  const { openDialogId, setOpenDialogId } = useDialog();
  const { emptyTrash } = useTrash(1, 10);

  return (
    <AlertDialog open={openDialogId === 'emptyTrashConfirmation'} onOpenChange={() => setOpenDialogId(null)}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete items in the Trash
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-red-500 hover:bg-red-600"
            onClick={() => {
              void emptyTrash().then(() => {
                toast({
                  title: 'Trash Empty',
                  description: 'Trash has been successfully cleared',
                });
              });
            }}
          >
            Empty Trash
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
