import { FC, useEffect, useRef, useState } from 'react';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { BookImage, Loader2, LucideFolder } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DestinationAccordion } from './destination-accordion';
import { ConditionalWrapper } from '../conditional-wrapper';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { VersionsAccordion } from '@/components/inspector/versions-accordion';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useForm, useWatch } from 'react-hook-form';
import { WorkflowAccordion } from './workflow-accordion';
import { InspectorImage } from '@/components/inspector/inspector-image';
import { MetadataEditor } from '@/components/inspector/metadata-accordion/metadata-editor';
import { TagsAccordion } from './tags-accordion';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { renameFolder } from '@/services/folder.service';
import { updateAsset } from '@/services/asset.service';
import { renameAlbum } from '@/services/album.service';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRouter } from 'next/router';
import { useFolder } from '@/hooks/data/folder/useFolder';
import { NodeType } from '@/types/tree';
import { useAlbum } from '@/hooks/data/albums/useAlbum';
import { useToast } from '@/components/ui/use-toast';
import { useAsset } from '@/hooks/data/assets/useAsset';
import { Textarea } from '@/components/ui/textarea';
import { Variants } from '@/types/asset';
import { useTree } from '@/hooks/data/tree/useTree';
import { Button } from '@/components/ui/button';
import { Asset } from '@/types/asset';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { z } from 'zod';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { useDatatableSelection } from '@/context/DatatableSelectionContext';
import { UserFields } from '@/components/inspector/entity-fields/user-fields';
import { InvitationFields } from '@/components/inspector/entity-fields/invitation-fields';
import { TagFields } from '@/components/inspector/entity-fields/tag-fields';
import { MetadataFields } from '@/components/inspector/entity-fields/metadata-fields';
import { WorkflowFields } from '@/components/inspector/entity-fields/workflow-fields';
import { VariantFields } from '@/components/inspector/entity-fields/variant-fields';
import { EntityType } from '@/types/entity';
import { SharesAccordion } from './shares-accordion';

export const Inspector: FC<{ data: Array<Asset> }> = ({ data }) => {
  const queryClient = useQueryClient();

  const { pathname } = useRouter();
  const { toast } = useToast();
  const { selectedAssetIds } = useAssetSelection();
  const { selectedEntity, selectedEntityType } = useDatatableSelection();
  const { currentSelectedType, selectedAlbum, selectedFolder } = useTree();
  const { isMobile } = useBreakpoint();
  const { isManagePage } = useCurrentPage();

  const [scrollY, setScrollY] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const isSingleAssetPage = pathname === '/asset/[id]';
  const multipleAssetSelected = selectedAssetIds.length > 1;
  const selectedAssets = data?.filter((asset) => selectedAssetIds.some((id) => id.id === asset.id));
  const stackedAssets = selectedAssets.slice(0, 5);

  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>([]);

  useEffect(() => {
    if (selectedAssetIds.length === 0) {
      setCurrentAccordionItems([]);
    } else {
      setCurrentAccordionItems(currentAccordionItems);
    }
  }, [selectedAssetIds, selectedFolder, selectedAlbum]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  /*   const SelectedAssetContainerMaxHeight: number = 176;
  const SelectedAssetContainerMinHeight: number = 50; */

  const formSchema = z.object({
    name: z.string().min(2, {
      message: 'Asset name must be at least 2 characters.',
    }),
    slug: z.string().optional(),
    description: z.any(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      slug: '',
      description: '',
    },
  });

  const watchedName = useWatch({ control: form.control, name: 'name' });
  const watchedDescription = useWatch({ control: form.control, name: 'description' });

  const updateAssetWithAuth = useAuthenticatedQueryFn(updateAsset);
  const renameAlbumWithAuth = useAuthenticatedQueryFn(renameAlbum);
  const renameFolderWithAuth = useAuthenticatedQueryFn(renameFolder);

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  const { asset, isAssetLoading, isAssetFetching } = useAsset(selectedAssetIds[0]?.id, form, {
    refetchOnWindowFocus: false,
    enabled: selectedAssetIds.length === 1 && currentSelectedType === NodeType.Assets,
  });

  const { album, isAlbumLoading, isAlbumFetching } = useAlbum(selectedAlbum, form, {
    refetchOnWindowFocus: false,
    enabled: currentSelectedType === NodeType.Albums && selectedAlbum !== undefined,
  });

  const { folder, isFolderLoading, isFolderFetching } = useFolder(selectedFolder, form, {
    refetchOnWindowFocus: false,
    enabled: currentSelectedType === NodeType.Folders && selectedFolder !== undefined,
  });

  const isLoading = isAssetLoading || isAlbumLoading || isFolderLoading;
  const isFetching = isAssetFetching || isAlbumFetching || isFolderFetching;

  // Reset "information" form if accordion is opened and asset data is fetching or loading
  useEffect(() => {
    if (isLoading || isFetching) {
      form.reset({
        name: '',
        slug: '',
        description: '',
      });
    }
  }, [form, isLoading, isFetching]);

  const updateAssetMutation = useMutation({
    mutationFn: updateAssetWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === asset?.name && watchedDescription !== asset?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      setIsEdit((prev) => prev === true && false);

      void queryClient.invalidateQueries({ queryKey: ['assetData'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameAlbumMutation = useMutation({
    mutationFn: renameAlbumWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === album?.name && watchedDescription !== album?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  const renameFolderMutation = useMutation({
    mutationFn: renameFolderWithAuth,
    onSuccess: (result) => {
      const onlyDescriptionChanged = watchedName === folder?.name && watchedDescription !== folder?.description;

      toast({
        title: onlyDescriptionChanged ? 'Description Updated' : 'Rename Successful',
        description: (
          <>
            {onlyDescriptionChanged ? (
              `The description for "${result.name}" has been successfully updated.`
            ) : (
              <>
                {currentSelectedType?.slice(0, -1)} has been successfully renamed to &quot;
                <span className="font-bold">{result.name}</span>
                &quot;.
              </>
            )}
          </>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['tree'] });

      setIsEdit((prev) => prev === true && false);
    },
    onError: (err: BackendError) => {
      handleError(err);
      setIsEdit(true);
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (currentSelectedType === NodeType.Folders) {
      renameFolderMutation.mutate({
        id: selectedFolder,
        name: values.name,
        description: values.description,
      });
    } else if (currentSelectedType === NodeType.Albums) {
      renameAlbumMutation.mutate({
        id: selectedAlbum,
        name: values.name,
        description: values.description,
        slug: values.slug,
      });
    } else {
      updateAssetMutation.mutate({
        id: selectedAssetIds[0].id,
        body: { name: values.name, description: values.description },
      });
    }
  }

  const handleScroll = () => {
    const position = scrollRef.current?.scrollTop ?? 0;
    setScrollY(position);
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [selectedAssetIds]);

  /*   const selectedAssetContainerHeight = Math.min(
    Math.max(SelectedAssetContainerMaxHeight - scrollY, SelectedAssetContainerMinHeight),
    SelectedAssetContainerMaxHeight,
  ); */

  const renderManageInspector = () => {
    switch (selectedEntityType) {
      case EntityType.User:
        return <UserFields user={selectedEntity!} />;
      case EntityType.Invitation:
        return <InvitationFields invitation={selectedEntity!} />;
      case EntityType.Tag:
        return <TagFields tag={selectedEntity!} />;
      case EntityType.Metadata:
        return selectedEntity?.original.id && <MetadataFields metadataId={selectedEntity?.original.id} />;
      case EntityType.Workflow:
        return <WorkflowFields workflow={selectedEntity!} />;
      case EntityType.Variant:
        return <VariantFields variant={selectedEntity!} />;
      default:
        return <div>Unknown entity type</div>;
    }
  };

  return (
    <div className="flex h-[calc(100dvh-56px)] flex-col @container/inspector md:h-screen">
      {isManagePage ? (
        <>
          {selectedEntity && selectedEntityType ? (
            <div className="relative h-screen overflow-y-scroll pt-20" ref={scrollRef}>
              {renderManageInspector()}
            </div>
          ) : (
            <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
              No row selected
            </h2>
          )}
        </>
      ) : (
        <>
          {selectedAssetIds.length > 0 || selectedFolder || selectedAlbum ? (
            <>
              <ConditionalWrapper
                condition={isMobile}
                wrapper={(children) => (
                  <DrawerHeader className="p-0">
                    <DrawerTitle>{children}</DrawerTitle>
                  </DrawerHeader>
                )}
              >
                <div
                  className={cn(
                    'sticky z-50 flex max-h-16 min-h-16 items-center justify-center gap-2 bg-white px-4 text-lg font-semibold tracking-tight dark:bg-black',
                    scrollY > 20 && 'border-b border-neutral-200 shadow-sm dark:border-[#333333]',
                  )}
                >
                  <p
                    className={cn(
                      'text-balance text-center text-neutral-950 transition-all duration-200 dark:text-neutral-50',
                      scrollY >= 96 ? 'mr-0' : '-mr-10',
                    )}
                  >
                    Selected{' '}
                    {currentSelectedType === NodeType.Assets
                      ? multipleAssetSelected && !isSingleAssetPage
                        ? 'assets'
                        : 'asset'
                      : currentSelectedType?.slice(0, -1)}
                  </p>

                  <div
                    className={cn(
                      'relative flex size-10 items-center transition-all duration-200',
                      scrollY >= 96 ? 'scale-100' : 'scale-0',
                    )}
                  >
                    {currentSelectedType === NodeType.Assets ? (
                      stackedAssets.map((props: Asset, index: number) => (
                        <InspectorImage
                          classname="absolute"
                          key={`${props.id}-header`}
                          {...props}
                          index={index}
                          stackedAssetsLength={stackedAssets.length}
                        />
                      ))
                    ) : (
                      <div
                        className={cn(
                          'flex aspect-square size-8 items-center justify-center rounded-md duration-200',
                          currentSelectedType === NodeType.Folders && 'bg-violet-100 dark:bg-violet-950',
                          currentSelectedType === NodeType.Albums && 'bg-neutral-100 dark:bg-neutral-950',
                        )}
                      >
                        {currentSelectedType === NodeType.Folders ? (
                          <LucideFolder className="size-5 stroke-violet-500 dark:stroke-violet-700" strokeWidth={2} />
                        ) : (
                          <BookImage className="size-5 stroke-neutral-600 dark:stroke-neutral-700" strokeWidth={2} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </ConditionalWrapper>

              <div className="overflow-y-scroll px-3 @[18rem]/inspector:px-6" ref={scrollRef}>
                <div
                  className={cn('relative mx-auto mb-12 mt-8 flex aspect-square w-full justify-center md:my-12')}
                  ref={containerRef}
                  style={{
                    width: `${asset?.variants[Variants.Thumbnail]?.width}px`,
                    height: `${asset?.variants[Variants.Thumbnail]?.height}px`,
                  }}
                >
                  {currentSelectedType === NodeType.Assets ? (
                    stackedAssets.map((props: Asset, index: number) => (
                      <InspectorImage
                        classname="absolute first:relative"
                        key={`${props.id}-container`}
                        {...props}
                        index={index}
                        stackedAssetsLength={stackedAssets.length}
                      />
                    ))
                  ) : (
                    <div
                      className={cn(
                        'flex aspect-square items-center justify-center rounded-md duration-200',
                        currentSelectedType === NodeType.Folders && 'bg-violet-100 dark:bg-violet-950',
                        currentSelectedType === NodeType.Albums && 'bg-teal-100 dark:bg-teal-950',
                      )}
                    >
                      {currentSelectedType === NodeType.Folders ? (
                        <LucideFolder className="size-14 stroke-violet-500 dark:stroke-violet-700" strokeWidth={1} />
                      ) : (
                        <BookImage className="size-14 stroke-teal-600 dark:stroke-teal-700" strokeWidth={1} />
                      )}
                    </div>
                  )}
                </div>

                <Accordion
                  type="multiple"
                  className="w-full"
                  onValueChange={(accordion) => {
                    setIsEdit(false);
                    setCurrentAccordionItems(accordion);
                  }}
                  value={currentAccordionItems}
                >
                  <AccordionItem value="information" disabled={multipleAssetSelected}>
                    <AccordionTrigger disabled={multipleAssetSelected}>Information</AccordionTrigger>
                    {!multipleAssetSelected && (
                      <AccordionContent className="m-1 flex flex-col">
                        <Form {...form}>
                          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <FormField
                              control={form.control}
                              name="name"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Name</FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isLoading={isLoading || isFetching}
                                      disabled={!isEdit}
                                      placeholder="Name"
                                      className={cn(
                                        'bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950',
                                        form.formState.errors.name &&
                                          '!border-red-500 dark:focus-visible:ring-red-500 ',
                                      )}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name="slug"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Slug</FormLabel>
                                  <FormControl>
                                    <Input
                                      {...field}
                                      isLoading={isLoading || isFetching}
                                      disabled
                                      placeholder="Slug"
                                      className={cn(
                                        'bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950',
                                        form.formState.errors.slug &&
                                          '!border-red-500 dark:focus-visible:ring-red-500 ',
                                      )}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={form.control}
                              name="description"
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>Description</FormLabel>
                                  <FormControl>
                                    <Textarea
                                      placeholder="Description"
                                      rows={4}
                                      {...field}
                                      disabled={!isEdit}
                                      isLoading={isLoading || isFetching}
                                      className="bg-white focus-visible:ring-offset-white dark:bg-neutral-950 dark:focus-visible:ring-offset-neutral-950"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                            <div className="flex justify-end">
                              <Button
                                type={isEdit ? 'button' : 'submit'}
                                variant={isEdit ? 'default' : 'outline'}
                                disabled={isFetching || isLoading}
                                onClick={() => setIsEdit((prev) => prev === false && true)}
                              >
                                {isLoading || isFetching ? (
                                  <Loader2 className="size-4 animate-spin stroke-neutral-700" />
                                ) : isEdit ? (
                                  'Save Information'
                                ) : (
                                  'Edit Information'
                                )}
                              </Button>
                            </div>
                          </form>
                        </Form>
                      </AccordionContent>
                    )}
                  </AccordionItem>
                  {currentSelectedType === NodeType.Assets && asset && (
                    <AccordionItem value="metadata" disabled={multipleAssetSelected}>
                      <AccordionTrigger disabled={multipleAssetSelected}>Metadata</AccordionTrigger>
                      {!multipleAssetSelected && (
                        <AccordionContent className="m-1 flex flex-col">
                          <MetadataEditor
                            currentAccordionItems={currentAccordionItems}
                            asset={asset}
                            scrollRef={scrollRef}
                          />
                        </AccordionContent>
                      )}
                    </AccordionItem>
                  )}
                  {currentSelectedType === NodeType.Assets && asset && (
                    <VersionsAccordion disabled={multipleAssetSelected} asset={asset} />
                  )}
                  {currentSelectedType !== NodeType.Folders && (
                    <DestinationAccordion
                      multipleAssetSelected={multipleAssetSelected}
                      item={currentSelectedType === NodeType.Assets ? asset : album}
                      currentSelectedType={currentSelectedType}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  {currentSelectedType === NodeType.Albums && (
                    <SharesAccordion currentAccordionItems={currentAccordionItems} />
                  )}

                  {currentSelectedType === NodeType.Assets && (
                    <TagsAccordion
                      asset={asset}
                      multipleAssetSelected={multipleAssetSelected}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  {currentSelectedType === NodeType.Assets && asset && (
                    <WorkflowAccordion
                      multipleAssetSelected={multipleAssetSelected}
                      item={asset}
                      currentAccordionItems={currentAccordionItems}
                    />
                  )}
                  <AuditLogAccordion
                    multipleAssetSelected={multipleAssetSelected}
                    selectedAssetIds={selectedAssetIds}
                    currentAccordionItems={currentAccordionItems}
                  />
                </Accordion>
              </div>
            </>
          ) : (
            <h2 className="flex h-full items-center justify-center text-balance p-4 text-center text-lg font-semibold tracking-tight text-neutral-400">
              No node selected
            </h2>
          )}
        </>
      )}
    </div>
  );
};
