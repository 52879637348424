import { z } from 'zod';

const userSchema = z.object({
  id: z.string(),
  user_id: z.string(),
  name: z.string(),
  role: z.string(),
  profile: z.object({
    email: z.string().nullable(),
  }),
});

export const getUsersResponseSchema = z.object({
  users: z.array(userSchema),
  pagination: z.object({
    count: z.number(),
    limit: z.number(),
    offset: z.number(),
  }),
});

export type User = z.infer<typeof userSchema>;
