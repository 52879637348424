import { RowEntity } from '@/context/DatatableSelectionContext';
import React, { HTMLProps, Ref, useEffect, useRef } from 'react';

type WorkflowFieldsProps = {
  workflow: RowEntity;
};

export const WorkflowFields = ({ workflow }: WorkflowFieldsProps) => {
  return <div>Workflows</div>;
};
