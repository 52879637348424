import { useRouter } from 'next/router';
import { usePagination, DOTS } from '@/hooks/usePagination';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { Pagination as PaginationTypes } from '@/types';
import { ulid } from 'ulid';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { FC, PropsWithChildren } from 'react';
import Link from 'next/link';
import { ChevronUp } from 'lucide-react';
import { ParsedUrlQuery } from 'querystring';

export const CustomPagination = ({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  handleNext,
  handlePrev,
  onPageChange,
  deselectAssetsOnPageChange,
  onlyIcons = false,
}: PaginationTypes) => {
  const { isMobile } = useBreakpoint();
  const { push, query } = useRouter();

  const { deselectAllAssets } = useAssetSelection();

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  return (
    <Pagination className={className}>
      <PaginationContent className={cn(isMobile && 'flex w-full justify-between')}>
        <PaginationItem>
          <PaginationPrevious
            onClick={handlePrev?.onClick}
            href={handlePrev?.href ?? {}}
            disabled={handlePrev?.disabled}
            onlyIcons={onlyIcons}
          />
        </PaginationItem>

        <ContentWrapper isMobile={isMobile} currentPage={currentPage}>
          {paginationRange?.map((pageNumber) => {
            return (
              <ItemWrapper
                push={() => push}
                key={ulid()}
                pageNumber={pageNumber}
                isMobile={isMobile}
                deselectAssetsOnPageChange={deselectAssetsOnPageChange}
                deselectAllAssets={deselectAllAssets}
                query={query}
                currentPage={currentPage}
                onPageChange={onPageChange}
              >
                {pageNumber}
              </ItemWrapper>
            );
          })}
        </ContentWrapper>

        <PaginationItem>
          <PaginationNext
            onClick={handleNext?.onClick}
            href={handleNext?.href ?? {}}
            disabled={handleNext?.disabled}
            onlyIcons={onlyIcons}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

const ContentWrapper: FC<PropsWithChildren<{ isMobile: boolean; currentPage?: number }>> = ({
  children,
  isMobile,
  currentPage,
}) =>
  isMobile ? (
    <DropdownMenu>
      <DropdownMenuTrigger className="group flex items-center">
        Page {currentPage === 0 ? 1 : currentPage}
        <ChevronUp className={cn('ml-2 size-4 transition-transform duration-150 group-data-[state=open]:rotate-180')} />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel className="flex justify-center">Pages</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    children
  );

const ItemWrapper: FC<
  PropsWithChildren<{
    isMobile: boolean;
    pageNumber: string | number;
    deselectAllAssets: () => void;
    deselectAssetsOnPageChange?: boolean;
    query: ParsedUrlQuery;
    currentPage?: number;
    push: (query: {
      query: { keyword?: string | Array<string>; page?: number | string; results?: number | string | Array<string> };
    }) => void;
    onPageChange?: (page: number | undefined) => void;
  }>
> = ({
  isMobile,
  children,
  pageNumber,
  currentPage,
  query,
  onPageChange,
  deselectAllAssets,
  deselectAssetsOnPageChange,
}) => {
  if (pageNumber === DOTS) {
    return isMobile ? (
      <DropdownMenuLabel
        className="flex items-center justify-center text-neutral-300"
        onClick={(e) => e.preventDefault()}
      >
        ...
      </DropdownMenuLabel>
    ) : (
      <PaginationItem>&#8230;</PaginationItem>
    );
  }

  return isMobile ? (
    (currentPage === 0 && pageNumber === 1) || pageNumber === currentPage ? (
      <DropdownMenuItem disabled className="justify-center" onClick={(e) => e.preventDefault()}>
        {children}
      </DropdownMenuItem>
    ) : (
      <Link href={{ query: { ...query, page: pageNumber } }}>
        <DropdownMenuItem className="justify-center">{children}</DropdownMenuItem>
      </Link>
    )
  ) : (
    <PaginationItem key={`page-${pageNumber}`}>
      <PaginationLink
        onClick={() => {
          if (deselectAssetsOnPageChange) {
            deselectAllAssets();
          }

          if (onPageChange !== undefined) {
            onPageChange!(Number(pageNumber));
          }
        }}
        href={
          onPageChange === undefined
            ? {
                query: {
                  ...query,
                  page: pageNumber,
                },
              }
            : { query }
        }
        isActive={(currentPage === 0 && pageNumber === 1) || pageNumber === currentPage}
      >
        {children}
      </PaginationLink>
    </PaginationItem>
  );
};
