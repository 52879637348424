import { useMemo } from 'react';
import {
  Binary,
  Calendar,
  CalendarClock,
  Dot,
  EllipsisVertical,
  RectangleHorizontal,
  Square,
  ToggleLeft,
} from 'lucide-react';
import { DataTable } from '@/components/data-table/data-table';
import { ColumnDef } from '@tanstack/react-table';
import { RectangleEllipsis } from 'lucide-react';
import { useDialog } from '@/context/DialogContext';
import { useAuth0 } from '@auth0/auth0-react';
import { CreateMetadataDialog } from '@/components/create-dialogs/create-metadata-dialog';
import { DeleteMetadataAlertDialog } from '@/components/delete-dialogs/delete-metadata-alert-dialog';
import { Button } from '@/components/ui/button';
import { IndeterminateCheckbox } from '@/components/data-table/data-table-checkbox';
import { useMetadataList } from '@/hooks/metadata/useMetadata';
import { metadataMap, MetadataField, metadataTypeOptions, FieldType } from '@/types/metadata';
import { useSort } from '@/hooks/useSort';
import { useSearchQuery } from '@/hooks/useSearchQuery';
import { Input } from '@/components/ui/input';
import { ListFilter } from 'lucide-react';
import { Trash2Icon, Plus, ArrowDownNarrowWide, ArrowUpNarrowWide } from 'lucide-react';
import { EntityType } from '@/types/entity';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/filter-select';
import { sortOptions, SortOrderOptions } from '@/types/sort';
import { useDatatableSelection } from '@/context/DatatableSelectionContext';
import { useDataTablePagination } from '@/hooks/usePagination';
import { StatusIndicator } from '@/components/data-table/data-table-status-indicator';
import { DataTableLoadingSkeleton } from '@/components/data-table/data-table-loading-skeleton';
import { DamLayout } from '@/components/layout';

const typeIconMap: Record<Exclude<FieldType, 'linked_assets'>, JSX.Element> = {
  string: <RectangleHorizontal className="w-full" />,
  text: <Square className="w-full" />,
  integer: <Binary className="w-full" />,
  float: <Binary className="w-full" />,
  boolean: <ToggleLeft className="w-full" />,
  date: <Calendar className="w-full" />,
  datetime: <CalendarClock className="w-full" />,
  select: <Dot className="w-full" />,
  multi_select: <EllipsisVertical className="w-full" />,
};

export const typeOptions = metadataTypeOptions
  .filter(({ value }) => value !== 'linked_assets') // Exclude linked assets type
  .map(({ label, value }) => ({
    value,
    label: (
      <div className="flex items-center gap-x-2">
        <div className="w-[16px]">{typeIconMap[value as Exclude<FieldType, 'linked_assets'>]}</div>
        {label}
      </div>
    ),
  }));

const Metadata = () => {
  const { isAuthenticated } = useAuth0();
  const { openModal } = useDialog();
  const { queryString, debouncedSearch, showFilterInput, setShowFilterInput } = useSearchQuery();
  const {
    pagination,
    setPagination,
    offset,
    limit,
    ready: paginationReady,
  } = useDataTablePagination(EntityType.Metadata);
  const { sort, handleOnSortChange, handleOnSortOrderChange, ready: sortReady } = useSort(EntityType.Metadata);
  const { selectedEntity, setSelectedEntity, setSelectedEntityType } = useDatatableSelection();

  const { metadataList, metadataListIsPending, metadataListIsFetching } = useMetadataList(
    {
      pagination: {
        offset,
        limit,
      },
      sort,
      queryString,
    },
    { enabled: isAuthenticated && paginationReady && sortReady },
  );
  const { metadata, pagination: paginationInfo } = metadataList;

  const columns = useMemo<Array<ColumnDef<MetadataField>>>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => (
          <IndeterminateCheckbox
            className="mx-1"
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
              id: row.id,
            }}
          />
        ),
      },
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => row.getValue('name'),
      },
      {
        accessorKey: 'type',
        header: 'Type',
        meta: {
          cellClassName: 'text-neutral-400 dark:text-neutral-600',
        },
        cell: ({ row }) => metadataMap[row.getValue('type') as keyof typeof metadataMap],
      },
      {
        accessorKey: 'facet',
        header: 'Facet',
        meta: {
          cellClassName: 'text-neutral-400 dark:text-neutral-600',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'facet'} />,
      },
      {
        accessorKey: 'searchable',
        header: 'Searchable',
        meta: {
          cellClassName: 'text-neutral-400 dark:text-neutral-600',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'searchable'} />,
      },
      {
        accessorKey: 'public',
        header: 'Public',
        meta: {
          cellClassName: 'text-neutral-400 dark:text-neutral-600',
        },
        cell: ({ row }) => <StatusIndicator row={row} value={'public'} />,
      },
    ],
    [],
  );

  return (
    <DamLayout>
      <div className="relative flex h-screen flex-col pt-20">
        <div className="flex max-h-[49px] min-h-[49px] flex-col justify-center border-b border-b-neutral-300 bg-neutral-200 pl-3 dark:border-b-black dark:bg-[#2D2D2D]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <div
                onClick={() => {
                  void openModal('createMetadata', 'createMetadata');
                }}
                className="flex cursor-pointer items-center gap-x-2"
              >
                <Plus className="size-5 text-neutral-400" />
                <div className="mr-4 text-xs">Create Metadata</div>
              </div>
              {selectedEntity && (
                <div
                  className="flex cursor-pointer items-center gap-x-2"
                  onClick={() => {
                    void openModal('deleteMetadataConfirmation', 'delete', {
                      id: selectedEntity.getValue('id'),
                      name: selectedEntity.getValue('name'),
                      onConfirm: () => setSelectedEntity(null),
                    });
                  }}
                >
                  <Trash2Icon className="size-5 text-neutral-400" />
                  <div className="mr-4 text-xs">Delete Metadata</div>
                </div>
              )}
            </div>

            <div className="flex h-12 items-center">
              <ListFilter
                onClick={() => setShowFilterInput && setShowFilterInput(!showFilterInput)}
                data-cy="metadata-filter-icon"
                className="mx-4 size-5 cursor-pointer text-violet-500"
              />
              <Select value={sort.value} onValueChange={handleOnSortChange}>
                <SelectTrigger>
                  <div className="flex size-full w-full items-center">
                    <div className="flex size-full w-full items-center justify-center bg-white px-2 dark:bg-neutral-950">
                      <SelectValue placeholder="Sort" />
                    </div>
                  </div>
                </SelectTrigger>
                <SelectContent>
                  {sortOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      <div>{label}</div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {sort.order === SortOrderOptions.ASC ? (
                <ArrowDownNarrowWide
                  onClick={handleOnSortOrderChange}
                  data-cy="metadata-sort-order-icon"
                  className="mx-4 size-4 cursor-pointer"
                />
              ) : (
                <ArrowUpNarrowWide
                  onClick={handleOnSortOrderChange}
                  data-cy="metadata-sort-order-icon"
                  className="mx-4 size-4 cursor-pointer"
                />
              )}
            </div>
          </div>
        </div>
        {showFilterInput && (
          <div className="flex items-center bg-white dark:bg-neutral-950">
            <ListFilter data-cy="metadata-search-filter-icon" className="mx-2 size-4" />
            <div className="text-sm font-bold">Filter: </div>
            <Input
              defaultValue={queryString}
              placeholder="Type here to filter results in this view"
              onChange={debouncedSearch}
              className="z-20 h-8 w-full rounded-none border-none bg-white focus-within:outline-none focus-within:ring-0 focus-within:ring-offset-0 focus-within:duration-0 dark:bg-black"
            />
          </div>
        )}
        {metadataListIsPending ? (
          <DataTableLoadingSkeleton />
        ) : metadata.length ||
          (!metadata.length && queryString !== '') ||
          (!metadata.length && queryString === '' && metadataListIsFetching) ? (
          <DataTable
            data={metadata}
            columns={columns}
            pagination={pagination}
            sort={sort}
            setPagination={setPagination}
            rowCount={paginationInfo.count}
            entity={EntityType.Metadata}
            queryString={queryString}
            setSelectedEntity={setSelectedEntity}
            setSelectedEntityType={setSelectedEntityType}
          />
        ) : (
          <div className="flex h-screen w-full flex-col items-center justify-center gap-2">
            <RectangleEllipsis className="mb-10 size-20 text-neutral-300 dark:text-neutral-600" strokeWidth={1} />
            <h4 className="text-2xl">No Metadata Fields</h4>
            <div className="mb-8 max-w-md text-balance px-16 text-center text-neutral-600">
              Create and manage the metadata. Start adding fields by clicking the &quot;New Field&quot; button!
            </div>
            <Button
              onClick={() => {
                void openModal('createMetadata', 'createMetadata');
              }}
            >
              Add Field
            </Button>
          </div>
        )}
      </div>

      <DeleteMetadataAlertDialog />
      <CreateMetadataDialog />
    </DamLayout>
  );
};

export default Metadata;
