import { DAM_URL } from '@/hooks/damRequest';
import axios from 'axios';

export default axios.create({
  baseURL: DAM_URL,
  headers: {
    Accept: 'application/json',
  },
  //   withCredentials: true,
});
