import { z } from 'zod';

export const folderSchema = z.object({
  createdAt: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  description: z.string(),
  destinations: z.string().array().optional(),
  id: z.string(),
  locked: z.boolean(),
  name: z.string(),
  nodeId: z.string(),
  parentFolderId: z.string().nullable(),
  slug: z.string(),
  tenantId: z.string(),
  updatedAt: z.string(),
});

export type Folder = z.infer<typeof folderSchema>;

const CreateFolderParams = folderSchema.pick({ name: true, description: true, parentFolderId: true }).partial({
  description: true,
  parentFolderId: true,
});

export type CreateFolderParams = z.infer<typeof CreateFolderParams>;

const DeleteFolderParams = folderSchema.pick({ id: true });

export type DeleteFolderParams = z.infer<typeof DeleteFolderParams>;

const RenameFolderParams = folderSchema.pick({ name: true, description: true, slug: true }).partial({
  description: true,
  slug: true,
});

export type RenameFolderParams = z.infer<typeof RenameFolderParams>;
