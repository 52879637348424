import { FC, forwardRef, SetStateAction, useState } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { GripHorizontal, Pen, Pencil, Plus, Save, Trash, Trash2 } from 'lucide-react';

interface OptionsProps {
  value: Record<string, string>;
  options: { [x: string]: string };
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  setOptions: (value: SetStateAction<{ [x: string]: string }>) => void;
  handleAddOption: () => void;
  handleRemoveOption: (key: string) => void;
}

export const Options: FC<OptionsProps> = forwardRef<HTMLDivElement, OptionsProps>(
  ({ handleAddOption, handleRemoveOption, options, className, disabled, isLoading, setOptions }, ref) => {
    const [editMode, setEditMode] = useState<string | null>(null); // Track the option being edited

    return (
      <ScrollArea
        ref={ref}
        className={cn(
          'w-full rounded-sm border border-neutral-200 bg-white pb-[36px] transition-[height] duration-150 dark:border-neutral-800 dark:bg-neutral-950',
          className,
          options && Object.keys(options).length > 0 ? 'h-72' : 'h-38',
          (disabled || isLoading) && 'pointer-events-none opacity-50',
        )}
      >
        <div className="relative flex flex-col">
          {options && Object.keys(options).length > 0 ? (
            Object.keys(options).map((optionKey) => (
              <div key={optionKey} className="flex h-12 flex-col justify-center gap-2 px-3">
                <div className="flex w-full items-center gap-2">
                  {editMode === optionKey ? (
                    // Pre-populated input field in edit mode
                    <Input
                      type="text"
                      value={options[optionKey]}
                      onChange={(e) =>
                        setOptions((prev) => ({
                          ...prev,
                          [optionKey]: e.target.value,
                        }))
                      }
                      className="h-9 bg-white dark:bg-neutral-950 dark:focus-within:ring-offset-neutral-950"
                      autoFocus
                    />
                  ) : (
                    // Display as text if not in edit mode
                    <>
                      <GripHorizontal className="size-5 text-[#333333]" />
                      <span title={options[optionKey]} className="line-clamp-1 flex-1 break-all text-left text-xs">
                        {options[optionKey]}
                      </span>
                    </>
                  )}
                  <div className="flex gap-1">
                    {editMode === optionKey ? (
                      <Button
                        onClick={() => {
                          // Check if the option is empty; if so, remove it
                          if (!options[optionKey].trim()) {
                            handleRemoveOption(optionKey);
                          }
                          setEditMode(null); // Exit edit mode
                        }}
                        variant="link"
                        className="group/action-button size-6 min-h-6 p-0 duration-150"
                        type="button"
                      >
                        <Save className="size-5 text-neutral-500 duration-150 group-hover/action-button:text-black dark:text-[#333333] dark:group-hover/action-button:text-white" />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setEditMode(optionKey)}
                        variant="link"
                        className="group/action-button size-6 min-h-6 p-0 duration-150"
                        type="button"
                      >
                        <Pencil className="size-5 text-neutral-500 duration-150 group-hover/action-button:text-black dark:text-[#333333] dark:group-hover/action-button:text-white" />
                      </Button>
                    )}
                    <Button
                      onClick={() => handleRemoveOption(optionKey)}
                      variant="link"
                      className="group/action-button size-6 min-h-6 p-0 duration-150"
                      type="button"
                    >
                      <Trash className="size-5 text-neutral-500 duration-150 group-hover/action-button:text-red-700 dark:text-[#333333] dark:group-hover/action-button:text-red-500" />
                    </Button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex h-28 flex-col items-center justify-center text-balance px-4 text-center text-sm text-neutral-500 dark:text-neutral-400">
              <p>
                There are no options, click <Plus className="inline size-4" /> to add more options.
              </p>
            </div>
          )}
        </div>

        {/* Add a new empty option to the end of the list */}
        <Button
          onClick={() => {
            handleAddOption();
            setEditMode(`option_${Object.keys(options).length + 1}`);
          }}
          type="button"
          className="absolute bottom-0 w-full justify-start rounded-none border border-x-neutral-300 border-b-neutral-300 border-t-neutral-200 bg-[#e9e9e9] px-3 shadow-none hover:bg-[#e9e9e9] dark:border-x-black dark:border-b-black dark:border-t-[#2D2D2D] dark:bg-[#232323] hover:dark:bg-[#232323]"
        >
          <Plus className="size-4 stroke-[#999999]" />
        </Button>
      </ScrollArea>
    );
  },
);

Options.displayName = 'Options';
