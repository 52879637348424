import { HTMLAttributes } from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { X } from 'lucide-react';

export function DatePickerWithRange({
  className,
  date,
  setDate,
  onDateChange,
  onClear,
  isOpen,
  setIsOpen,
}: HTMLAttributes<HTMLDivElement> & {
  date: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  onDateChange: (date: DateRange | undefined) => void;
  onClear: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  return (
    <div className={cn('grid gap-2')}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'flex h-9 w-[250px] justify-start gap-3 px-3 text-left font-normal',
              className,
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon />
            {date?.from ? (
              date.to ? (
                `${format(date.from, 'LLL dd, y')} - ${format(date.to, 'LLL dd, y')}`
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(range) => {
              setDate(range);
              onDateChange(range);
            }}
            numberOfMonths={2}
          />
          <Button onClick={onClear} className="mx-auto mb-4 flex gap-2" disabled={!date}>
            <X className="size-4" />
            Clear date range
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  );
}
