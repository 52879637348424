import Link from 'next/link';
import { LucideIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useRouter } from 'next/router';

interface NavProps {
  section: {
    title: string;
    icon: LucideIcon;
  };
  links: Array<{
    title: string;
    icon: LucideIcon;
    path: string;
    disabled: boolean;
  }>;
}

export function SidebarNav({ links, section }: NavProps) {
  const { pathname } = useRouter();

  return (
    <div data-testid="sidebar-nav" className="flex flex-col">
      <nav className="flex flex-col space-y-px">
        {section && (
          <div className="flex max-h-[48px] min-h-[48px] items-center gap-x-2 bg-neutral-200 p-0 px-3 text-neutral-500 dark:bg-[#2D2D2D] dark:text-neutral-400">
            <section.icon className="size-6 min-h-6 min-w-6" />
            <div className="line-clamp-1 break-all text-left text-sm font-bold uppercase">{section.title}</div>
          </div>
        )}

        {links?.map((link, index) => (
          <Link
            aria-disabled={link.disabled}
            tabIndex={link.disabled ? -1 : undefined}
            className={cn(
              'flex items-center gap-x-2 gap-y-1 bg-neutral-100 py-3  pl-3 hover:bg-neutral-50 dark:bg-[#222222] hover:dark:bg-[#323232]',
              link.path === pathname && 'bg-[#BA4AFF] hover:bg-[#be57ff] dark:bg-[#BA4AFF] dark:hover:bg-[#be57ff]',
              link.disabled ? 'pointer-events-none opacity-50' : '',
            )}
            key={index}
            href={link.path}
          >
            <link.icon
              className={cn('h-6 min-h-6 w-6 min-w-6 text-[#999999]', link.path === pathname && 'text-slate-900')}
            />
            <div className={cn('line-clamp-1 break-all pr-2 text-left', link.path === pathname && 'text-slate-900')}>
              {link.title}
            </div>
          </Link>
        ))}
      </nav>
    </div>
  );
}
