import { ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase } from 'react-select';
import { CaretDownIcon, Cross2Icon } from '@radix-ui/react-icons';

export const DropdownIndicator = <TOption, IsMulti extends boolean, Group extends GroupBase<TOption>>(
  props: DropdownIndicatorProps<TOption, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon className="size-4" />
  </components.DropdownIndicator>
);

export const ClearIndicator = <TOption, IsMulti extends boolean, Group extends GroupBase<TOption>>(
  props: ClearIndicatorProps<TOption, IsMulti, Group>,
) => (
  <components.ClearIndicator {...props}>
    <Cross2Icon className="size-3" />
  </components.ClearIndicator>
);
