import { Command } from '@/context/CommandContext';
import { useFolder } from '@/hooks/data/folder/useFolder';

export const CreateFolderCommand = () => {
  const { unDeleteFolderMutation, deleteFolderMutation } = useFolder();

  let id = '';

  // This is currently a partial command that just handles the undo/redo
  // The folder is being created in edit-create-folder-album-dialog

  const apply = () => {
    return;
  };

  const update = (params: { id: string }) => {
    id = params.id;
  };

  const undo = (): void => {
    void deleteFolderMutation({ id });
  };

  const redo = (): void => {
    void unDeleteFolderMutation({ id });
  };

  return {
    apply,
    update,
    undo,
    redo,
  };
};
