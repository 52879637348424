import { damRequest } from '@/hooks/damRequest';
import { assetSchema } from '@/types/asset';
import { workflowSchema, workflowListSchema } from '@/types/workflow';

const BASE = '/api/v1/internal/workflows';

const getWorkflow = async (params?: null, token?: string) => {
  const response = await damRequest('GET', BASE, token);
  return workflowSchema.parse(response);
};

const getWorkflowList = async (
  params: {
    pagination: { limit: number; offset: number };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset}&limit=${params.pagination?.limit}&filterQuery=${params.queryString}&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return workflowListSchema.parse(response);
};

const deleteWorkflow = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

const setWorkflow = async (
  { asset_id, workflows }: { asset_id?: string | null; workflows?: object | null },
  token?: string,
) => {
  const response = await damRequest('PATCH', `/api/v1/internal/assets/${asset_id}`, token, {
    body: { workflows },
  });

  return assetSchema.parse(response);
};

export { getWorkflow, setWorkflow, getWorkflowList, deleteWorkflow };
