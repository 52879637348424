import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';

import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isLoading?: boolean;
  prependIcon?: ReactNode;
  appendIcon?: ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, prependIcon, appendIcon, type, isLoading, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex h-10 w-full items-center rounded-md border border-neutral-200 bg-transparent px-3 py-1 shadow-sm transition duration-150 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-within:outline-none focus-within:ring-2 focus-within:ring-neutral-500 focus-within:ring-offset-2 focus-within:duration-150 dark:border-neutral-800 dark:focus-within:ring-neutral-500',
        className,
        props.disabled && 'cursor-not-allowed opacity-50', // it is not enough to just add a class with `disabled:` prefix, because div element does not have disabled attribute and the classes would never get applied
      )}
    >
      {prependIcon}
      <input
        type={type}
        className="h-10 w-full bg-transparent text-sm outline-none placeholder:text-neutral-500 disabled:cursor-not-allowed dark:placeholder:text-neutral-400"
        {...props}
      />
      {isLoading && <Loader2 className="ml-2 size-4 animate-spin opacity-50" strokeWidth={2.5} />}
      {appendIcon}
    </div>
  ),
);

Input.displayName = 'Input';

export { Input };
