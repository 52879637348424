export enum DraggableItems {
  FOLDER = 'folder',
  ALBUM = 'album',
  ASSET = 'asset',
}

export type DragItem = {
  type: string;
  nodeId: string;
  id: string;
  currentParent: string;
  oldPosition?: number;
  index?: number;
};
