import { useEffect, useState, Fragment, useMemo } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { TenantUserRole, TenantUserRoleMap, tenantUserRoleMap, tenantUserRoleOptions } from '@/types/tenantUserRole';
import { BackendError, useFormErrorHandler } from '@/hooks/useFormErrorHandler';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { error as errorLog, warn } from '@/utilities/log';
import { FormProvider, useForm } from 'react-hook-form';
import { AuditLogAccordion } from '@/components/inspector/audit-log-item';
import { Loader2, Save } from 'lucide-react';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { ToastAction } from '@/components/ui/toast';
import { updateUser } from '@/services/users.service';
import { RowEntity } from '@/context/DatatableSelectionContext';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { useToast } from '@/components/ui/use-toast';
import { useUser } from '@/hooks/users/useUsers';
import { Button } from '@/components/ui/button';
import { User } from '@/types/users';
import { cn } from '@/lib/utils';
import { z } from 'zod';

type UserFieldsProps = {
  user: RowEntity;
};

export const UserFields = ({ user: userProp }: UserFieldsProps) => {
  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['user-profile']);

  const queryClient = useQueryClient();

  const { user_id } = userProp.original as User;
  const { toast } = useToast();
  const { user, userIsFetching, userIsPending } = useUser(user_id, {
    enabled: Boolean(user_id),
  });

  const editUserWithAuth = useAuthenticatedQueryFn(updateUser);

  const editUserMutation = useMutation({
    mutationFn: editUserWithAuth,
    onSuccess: (result) => {
      // toast({
      //   title: 'User updated',
      //   description: `User has been successfully updated.`,
      // });

      toast({
        title: 'User Role Updated',
        description: (
          <>
            The role for &quot;{result.name}&quot; has been successfully updated to{' '}
            <span className="font-bold">{result.role}</span>.
          </>
        ),
      });

      void queryClient.invalidateQueries({ queryKey: ['userList'] });
    },
    onError: (err: BackendError) => handleError(err),
  });

  const isLoading = editUserMutation.status === 'pending';

  const formFields: Array<any> = useMemo(
    () => [
      {
        'name': 'Name',
        'slug': 'name',
        'type': 'string',
        'disabled': true,
        'value': user.name,
        'data-cy': 'user-field-name',
      },
      // Only show email field if user.profile has an email
      ...(user.profile?.email
        ? [
            {
              'name': 'Email',
              'slug': 'email',
              'type': 'string',
              'disabled': true,
              'value': user.profile.email,
              'data-cy': 'user-email-field',
            },
          ]
        : []),
      {
        'name': 'Role',
        'slug': 'role',
        'type': 'select',
        'options': tenantUserRoleOptions,
        'disabled': userIsFetching || userIsPending || isLoading,
        'placeholder': tenantUserRoleMap[user.role as keyof TenantUserRoleMap],
        'validation': z.enum(Object.values(TenantUserRole) as [string, ...Array<string>], {
          required_error: 'Role is required.',
        }),
        'value': user.role,
        'data-cy': 'user-role-field',
      },
    ],
    [user, userIsFetching, userIsPending, isLoading],
  );

  const formSchema = z.object(
    formFields.reduce(
      (acc, field) => {
        if (field.validation) {
          // Include only fields with validation property in the form schema
          acc[field.slug] = field.validation;
        }
        return acc;
      },
      {} as Record<string, z.ZodType<any>>,
    ),
  );

  const defaultValues = formFields.reduce(
    (acc, field) => {
      acc[field.slug] = field.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  // Populate form when `user.user_id` changes
  useEffect(() => {
    form.reset(defaultValues);
  }, [user, form.reset]);

  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      if (typeof values === 'object' && values !== null) {
        editUserMutation.mutate({
          id: user.user_id,
          body: {
            role: values.role,
          },
        });
      } else {
        warn('Invalid data for editing user');
      }
    } catch (err) {
      errorLog('Action failed:', err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Something went wrong',
        action: <ToastAction altText="Close">Close</ToastAction>,
      });
    }
  }

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['user-profile']}
    >
      <AccordionItem className="border-0" value="profile">
        <AccordionTrigger className="h-[49px] border-y border-b-neutral-300 border-t-[#2D2D2D] bg-neutral-200 p-3 hover:no-underline @[18rem]/inspector:py-3 dark:border-b-black dark:bg-[#222222]">
          Profile
        </AccordionTrigger>
        <AccordionContent className="flex flex-col border-b border-b-black bg-neutral-200 p-3 dark:bg-[#1C1C1C]">
          {userIsFetching ? (
            <div className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]">
              {formFields.map((field, index) => (
                <Fragment key={`skeleton-${field.slug}`}>
                  <div className="flex flex-col space-y-3">
                    {field.name && <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index % 2 })} />}
                    {['text', 'string', 'float', 'integer', 'select', 'date'].includes(field.type) && (
                      <Skeleton className="h-8 w-full" />
                    )}
                    {field.description && <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index % 2 })} />}
                  </div>
                  <Separator className="block last:hidden" />
                </Fragment>
              ))}
            </div>
          ) : (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {formFields.map((field) =>
                  renderField(form, field, field.slug === 'role' ? 'hidden' : 'flex @[24rem]/inspector:hidden'),
                )}

                <Button
                  disabled={userIsFetching || isLoading}
                  type="submit"
                  variant={'default'}
                  className="w-full justify-center @[21rem]/inspector:w-auto"
                  data-cy="user-save-button"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 size-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 size-4" />
                      Save
                    </>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
        </AccordionContent>
      </AccordionItem>
      <AuditLogAccordion
        multipleAssetSelected={false}
        selectedAssetIds={[{ id: user.id, name: user.name }]}
        currentAccordionItems={currentAccordionItems}
        entity="User"
        accordionTriggerClassName="bg-white-800 p-3 @[18rem]/inspector:py-3 dark:bg-neutral-800"
      />
    </Accordion>
  );
};
