import { FC } from 'react';
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
  ArrowUpCircleIcon,
  DocumentIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { cn } from '@/lib/utils';
import { UploadStatus as UploadStatusType } from '@/components/upload-dialog/asset-uploader';

type UploadStatusProps = {
  status: UploadStatusType;
  uploadProgress: number;
};

export const UploadStatus: FC<UploadStatusProps> = ({ status, uploadProgress }) => {
  let IconComponent: React.ComponentType<any> = InformationCircleIcon;
  let iconColorClass;
  let message;

  switch (status) {
    case 'no-folder':
      IconComponent = DocumentIcon;
      iconColorClass = 'text-neutral-400';
      message = 'No folder selected to upload into';
      break;
    case 'no-files':
      IconComponent = DocumentIcon;
      iconColorClass = 'text-neutral-400';
      message = 'No files to upload';
      break;
    case 'success':
      IconComponent = CheckCircleIcon;
      iconColorClass = 'text-green-500';
      message = 'Assets are created and uploaded';
      break;
    case 'ready':
      IconComponent = ArrowUpCircleIcon;
      iconColorClass = 'text-amber-400';
      message = 'Ready for upload';
      break;
    case 'uploading':
      iconColorClass = 'text-blue-500';
      message = `Uploading... ${uploadProgress}%`;
      break;
    case 'error':
    case 'failed':
      IconComponent = ExclamationCircleIcon;
      iconColorClass = 'text-red-500';
      message = 'Upload failed';
      break;
    default:
      iconColorClass = '';
      message = '';
  }

  return (
    <div className={cn('flex items-center', iconColorClass)}>
      {status === 'uploading' ? (
        <svg
          viewBox="0 0 48 48"
          className={cn('z-10 mr-2 size-5 rotate-[270deg] transition-all duration-100', iconColorClass)}
          stroke="currentColor"
          strokeWidth={5}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle
            cx="24"
            cy="24"
            r="18"
            className="fill-neutral-200 dark:fill-neutral-900"
            style={{
              strokeDasharray: '113.097',
              strokeDashoffset: `calc(113.097 - (${uploadProgress} / 100 * 113.097))`,
              transition: 'stroke-dashoffset 0.5s ease-in-out',
            }}
          />
        </svg>
      ) : (
        <IconComponent className={cn('mr-2 size-5', iconColorClass)} />
      )}

      {message}
    </div>
  );
};
