export const controlStyles = {
  disabled: '!cursor-not-allowed !opacity-50',
  base: 'w-full relative !rounded-md border text-sm shadow-sm ring-offset-neutral-200 !bg-white dark:!bg-neutral-950',
  focus:
    '!outline-none dark:!border-neutral-800 !border-neutral-200 hover:!border-neutral-200 dark:!hover:border-neutral-800 !ring-2 !ring-neutral-500 !ring-offset-2 !duration-150 dark:!ring-neutral-500 dark:!ring-offset-neutral-900',
  nonFocus:
    'border dark:!border-neutral-800 !border-neutral-200 hover:!border-neutral-200 dark:!hover:border-neutral-800',
};
export const placeholderStyles = 'dark:!text-neutral-400 !text-neutral-500 pl-1';
export const selectInputStyles = 'pl-0.5 dark:text-white text-black';
export const valueContainerStyles = '!px-1 !py-1 !overflow-y-scroll min-h-9 max-h-[145px] gap-1';
export const singleValueStyles = 'leading-7 ml-1 dark:text-white';
export const multiValueStyles = '!rounded-[4px] items-center py-0 !m-0 pr-1 !bg-neutral-950 dark:!bg-neutral-100';
export const multiValueLabelStyles = 'leading-none !text-sm !font-medium !text-white dark:!text-black';
export const multiValueRemoveStyles =
  'dark:hover:!bg-red-200 hover:!bg-red-900 hover:!text-red-300 dark:hover:text-red-500 duration-150 text-neutral-300 dark:text-neutral-500 !rounded-[2px] !p-0';
export const indicatorsContainerStyles = '';
export const clearIndicatorStyles =
  '!p-2 !text-neutral-300 dark:!text-neutral-400 dark:hover:!text-red-800 hover:!text-red-400';
export const indicatorSeparatorStyles = '!bg-neutral-100 dark:!bg-neutral-900';
export const dropdownIndicatorStyles =
  '!p-2 !text-neutral-300 dark:!text-neutral-400 dark:hover:!text-neutral-200 hover:!text-neutral-500';
export const menuStyles =
  '!z-50 px-1 dark:!bg-neutral-950 !rounded-md border border-neutral-200 bg-white text-neutral-950 !shadow-md dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50';
export const groupHeadingStyles = 'ml-3 mt-2 mb-1 text-neutral-500 text-sm';
export const optionStyles = {
  base: 'cursor-default !rounded py-1.5 pl-2 pr-8 text-sm',
  focus: '!bg-neutral-100 dark:!bg-neutral-800',
  selected: "after:content-['✔'] after:ml-2 after:text-green-500 !text-black dark:!text-white",
  disabled: '!pointer-events-none !opacity-50',
};
export const noOptionsMessageStyles =
  'text-neutral-500 !py-4 bg-neutral-50 dark:bg-neutral-950 border border-dashed border-neutral-200 dark:border-neutral-800 rounded-sm m-1';
