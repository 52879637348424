export type SortState = {
  value: string;
  order: string;
};

export enum SortOrderOptions {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortOrderValues {
  CREATED_AT = 'created_at',
}

export const sortOptions = [
  {
    label: 'Creation date',
    value: SortOrderValues.CREATED_AT,
  },
];
