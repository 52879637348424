import { FC, forwardRef } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Option } from '@/types/select-option';

interface MetadataSelectProps {
  'value': string;
  'onChange': (value: string) => void;
  'options': Array<Option>;
  'className'?: string;
  'disabled'?: boolean;
  'isLoading'?: boolean;
  'placeholder'?: string;
  'data-cy'?: string;
}

export const MetadataSelect: FC<MetadataSelectProps> = forwardRef<HTMLDivElement, MetadataSelectProps>(
  ({ onChange, options, className, disabled, isLoading, placeholder, value, ...props }) => {
    const handleChange = (selectedValue: string) => {
      onChange(selectedValue);
    };

    return (
      <Select onValueChange={handleChange} disabled={disabled ?? isLoading} value={value}>
        <SelectTrigger
          className={className}
          {...(props['data-cy'] ? { 'data-cy': `${props['data-cy']}-trigger` } : {})}
        >
          <SelectValue placeholder={placeholder ?? 'Select an option'} />
        </SelectTrigger>
        <SelectContent {...(props['data-cy'] ? { 'data-cy': `${props['data-cy']}-dropdown` } : {})}>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  },
);

MetadataSelect.displayName = 'MetadataSelect';
