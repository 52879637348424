import { useAlbum } from '@/hooks/data/albums/useAlbum';

export const CreateAlbumCommand = () => {
  const { unDeleteAlbumMutation, deleteAlbumMutation } = useAlbum();

  let id = '';

  // This is currently a partial command that just handles the undo/redo
  // The album is being created in edit-create-folder-album-dialog

  const apply = () => {};

  const update = (params: { id: string }) => {
    id = params.id;
  };

  const undo = (): void => {
    void deleteAlbumMutation({ id });
  };

  const redo = (): void => {
    void unDeleteAlbumMutation({ id });
  };

  return {
    apply,
    update,
    undo,
    redo,
  };
};
