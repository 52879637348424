import Image from 'next/image';
import { FC } from 'react';
import { FileText, FileVideo, FileArchive, FileSpreadsheet, FileCode, File, FileImage } from 'lucide-react';

type AssetPreviewProps = {
  preview: string;
  type: string;
};

export const AssetPreview: FC<AssetPreviewProps> = ({ preview, type }) => {
  switch (type) {
    case 'video/mp4':
      if (preview) {
        return (
          <video className="absolute inset-0 size-full">
            <source src={preview} />
          </video>
        );
      } else {
        return (
          <div className="flex h-full items-center justify-center">
            <FileVideo strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
          </div>
        );
      }
    case 'text/plain':
    case 'application/rtf':
      return (
        <div className="flex h-full items-center justify-center">
          <FileText strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return (
        <div className="flex h-full items-center justify-center">
          <File strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'text/xml':
    case 'application/xml':
      return (
        <div className="flex h-full items-center justify-center">
          <FileCode strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'application/pdf':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return (
        <div className="flex h-full items-center justify-center">
          <FileText strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return (
        <div className="flex h-full items-center justify-center">
          <FileSpreadsheet strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'application/zip':
    case 'application/gzip':
      return (
        <div className="flex h-full items-center justify-center">
          <FileArchive strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/tiff':
      if (preview) {
        return <Image src={preview} className="object-cover" alt="" fill onLoad={() => URL.revokeObjectURL(preview)} />;
      } else {
        return (
          <div className="flex h-full items-center justify-center">
            <FileImage strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
          </div>
        );
      }
    default:
      return (
        <div className="flex h-full items-center justify-center">
          <File strokeWidth={0.5} size={48} className="stroke-neutral-500 dark:stroke-neutral-400" />
        </div>
      );
  }
};
