import { useTrash } from '@/hooks/data/trash/useTrash';
import { useTree } from '@/hooks/data/tree/useTree';
import { TrashNodesResponse } from '@/types/trash';

export const RestoreTrashCommand = (nodes: Array<string>) => {
  const { trashNodes, restoreNodes } = useTree();
  const { refetchTrash } = useTrash(1, 10);

  const apply = (): Promise<TrashNodesResponse> => {
    return restoreNodes({ nodes });
  };

  const undo = async (): Promise<void> => {
    await trashNodes({ nodes });
    await refetchTrash();
  };

  const redo = async (): Promise<void> => {
    await apply();
    await refetchTrash();
  };

  return {
    apply,
    undo,
    redo,
  };
};
