import { FC, PropsWithChildren, useState } from 'react';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

type DestinationSwitchProps = {
  id: string;
  checked: boolean;
  handleDestinationToggle: (id: string, enabled: boolean) => void;
};

export const DestinationSwitch: FC<PropsWithChildren<DestinationSwitchProps>> = ({
  id,
  children,
  checked,
  handleDestinationToggle,
}) => {
  const [checkedState, setCheckedState] = useState(checked);

  return (
    <div className="flex items-center space-x-3">
      <Switch
        id={`destination-${id}`}
        checked={checkedState}
        onCheckedChange={(e) => {
          setCheckedState(e);
          handleDestinationToggle(id, e);
        }}
      />
      <Label htmlFor={`destination-${id}`} className="hover:cursor-pointer">
        {children}
      </Label>
    </div>
  );
};
