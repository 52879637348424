import { ComponentType } from 'react';
import { FieldType } from '@/types/metadata';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { MultiSelect } from '@/components/inspector/metadata-accordion/metadata-multiselect';
import { MetadataSelect } from '@/components/inspector/metadata-accordion/metadata-select';
import { DatePicker } from '@/components/ui/datepicker';
import { MetadataSwitch } from './metadata-switch';
import { MetadataDocuments } from '@/components/inspector/metadata-accordion/metadata-documents';

export const FieldComponents: Record<FieldType, ComponentType<any>> = {
  string: Input,
  text: Textarea,
  integer: Input,
  float: Input,
  boolean: MetadataSwitch,
  date: DatePicker,
  datetime: DatePicker,
  select: MetadataSelect,
  multi_select: MultiSelect,
  linked_assets: MetadataDocuments,
};
