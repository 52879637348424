import { FC, forwardRef, useEffect, useState, ChangeEvent } from 'react';
import { cn } from '@/lib/utils';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format, isValid, parse, parseISO } from 'date-fns';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

type DatePickerProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  className?: string;
  open?: boolean;
  setOpen?: (value: boolean) => void;
  disabled?: boolean;
};

export const DatePicker: FC<DatePickerProps> = forwardRef<HTMLInputElement, DatePickerProps>(
  ({ value, open, setOpen, disabled, className = '', ...props }, ref) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
    const [month, setMonth] = useState(new Date());

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value); // keep the input value in sync

      const parsedDate = parse(e.target.value, 'yyyy-MM-dd', new Date());

      if (isValid(parsedDate) && e.target.value.length === 10) {
        setSelectedDate(parsedDate);
        setMonth(parsedDate);
        props.onChange(format(parsedDate, 'yyyy-MM-dd'));
      } else if (e.target.value.length === 0) {
        setSelectedDate(undefined);
        props.onChange(null);
      } else {
        setSelectedDate(undefined);
        props.onChange(e.target.value);
      }
    };

    const handleDayPickerSelect = (date: Date | undefined) => {
      if (!date) {
        setInputValue('');
        setSelectedDate(undefined);
      } else {
        setSelectedDate(date);
        setMonth(date);
        setInputValue(format(date, 'yyyy-MM-dd'));
        props.onChange(format(date, 'yyyy-MM-dd'));
      }
      if (setOpen) {
        setOpen(false);
      }
    };

    useEffect(() => {
      if (!value) {
        // If the user input an invalid date
        setSelectedDate(undefined);
        if (value === '') {
          //If X button is clicked
          setInputValue('');
        }
      } else {
        const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
        setSelectedDate(parsedDate);
        if (isValid(parsedDate) && value.length === 10) {
          setInputValue(format(parsedDate, 'yyyy-MM-dd'));
        }
      }
    }, [value]);

    return (
      <Input
        type="text"
        ref={ref}
        className={cn('pl-0', className)}
        onClick={(e) => (e.target as HTMLInputElement).focus()}
        value={inputValue}
        placeholder="YYYY-MM-DD"
        prependIcon={
          <Popover modal={true} {...(open !== null && { open: open, onOpenChange: setOpen })}>
            <PopoverTrigger asChild>
              <Button
                variant="ghost"
                className="ml-px px-3 !text-neutral-400  duration-150 hover:bg-transparent hover:!text-neutral-950 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-neutral-200 dark:hover:bg-transparent dark:hover:!text-neutral-100 dark:focus-visible:ring-neutral-900"
                aria-label="Open date selector"
              >
                <CalendarIcon className="size-4" strokeWidth={1.5} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                captionLayout="dropdown"
                fromYear={1900}
                toYear={new Date().getFullYear()}
                month={month}
                onMonthChange={setMonth}
                mode="single"
                selected={selectedDate}
                onSelect={handleDayPickerSelect}
                defaultMonth={selectedDate ? new Date(selectedDate) : undefined}
                classNames={{ caption_label: 'hidden' }}
                disabled={disabled}
              />
            </PopoverContent>
          </Popover>
        }
        {...props}
        onChange={handleInputChange}
      />
    );
  },
);

DatePicker.displayName = 'DatePicker';
