import { useEffect, useState, useMemo } from 'react';
import { throttle } from 'lodash';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

const tailwindScreens = fullConfig.theme.screens;

const breakpoints = Object.keys(tailwindScreens).map((breakpoint) => ({
  label: breakpoint,
  size: parseInt(tailwindScreens[breakpoint as keyof typeof tailwindScreens], 10),
}));

type Breakpoint = {
  label: string;
  size: number;
};

/**
 * Gets current breakpoint size
 * @param {number} width screen width
 * @returns {string} current breakpoint name
 */
const getDeviceConfig = (width: number): string => {
  let output: string | undefined;

  breakpoints.every(({ label, size }: Breakpoint) => {
    if (width < size) {
      output = label;
      return false;
    } else {
      return true;
    }
  });

  return output ?? breakpoints[breakpoints.length - 1].label;
};

type UseBreakpointReturn = {
  breakpoint: string | undefined;
  isMobile: boolean;
};

/**
 * Returns a breakpoint string based on screen size
 * @returns {string} "xs", "sm", "md", "lg"
 */
export const useBreakpoint = (): UseBreakpointReturn => {
  const [breakpoint, setBreakpoint] = useState<string>('');

  const isMobile = useMemo(() => ['md', 'sm'].includes(breakpoint), [breakpoint]);

  const throttledCalcInnerWidth = useMemo(() => {
    const calcInnerWidth = () => {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    };
    return throttle(() => calcInnerWidth(), 200);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', throttledCalcInnerWidth);
    const timeout = setTimeout(throttledCalcInnerWidth, 0);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', throttledCalcInnerWidth);
    };
  }, [throttledCalcInnerWidth]);

  return { breakpoint, isMobile };
};
