import { ComponentPropsWithRef, useEffect, useRef } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { CheckedState } from '@radix-ui/react-checkbox';
import { cn } from '@/lib/utils';

export const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  id,
  ...rest
}: { indeterminate?: boolean } & ComponentPropsWithRef<typeof Checkbox>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <div ref={ref} className="flex">
      <Checkbox
        data-cy={`checkbox-row-id-${id}`}
        onCheckedChange={rest.onChange as unknown as (checked: CheckedState) => void}
        disabled={rest.disabled}
        {...rest}
      />
    </div>
  );
};
