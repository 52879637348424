import { damRequest } from '@/hooks/damRequest';
import { getTreeBranchNodeResponseSchema, getTreeRootNodeResponseSchema, moveNodesResponseSchema } from '@/types/tree';

export const getRootNodes = async (params?: null, token?: string) => {
  const response = await damRequest('GET', '/api/v1/internal/tree', token, {});
  return getTreeRootNodeResponseSchema.parse(response);
};

export const getNode = async (params: { nodeId: string; types?: string }, token?: string) => {
  const response = await damRequest('GET', `/api/v1/internal/tree/${params.nodeId}?types=${params?.types}`, token, {});
  return getTreeBranchNodeResponseSchema.parse(response);
};

export const moveNodesQuery = async (
  params?: Array<{ parentFolderNodeId?: string; nodes: Array<string> }>,
  token?: string,
) => {
  const response = await damRequest('POST', '/api/v1/tree/move', token, { body: { move: params } });
  return moveNodesResponseSchema.parse(response);
};
