import { useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from '@/utilities/localStorage';
import { useRouter } from 'next/router';
import { EntityType } from '@/types/entity';
import { SortOrderOptions, SortOrderValues, SortState } from '@/types/sort';

export const useSort = (entity: EntityType) => {
  const router = useRouter();
  const { query } = router;
  const [ready, setReady] = useState<boolean>(false);
  const [sort, setSort] = useState<SortState>({
    value: (query?.sortBy as string) ?? SortOrderValues.CREATED_AT,
    order: (query?.sortOrder as string) ?? SortOrderOptions.ASC,
  });

  useEffect(() => {
    if (!query.sortValue && !query.sortOrder) {
      const localStorageSortValue = getLocalStorage(`${entity}/datatable/sort/value`);
      const localStorageSortOrder = getLocalStorage(`${entity}/datatable/sort/order`);
      setSort({
        value: localStorageSortValue ?? sort.value,
        order: localStorageSortOrder ?? sort.order,
      });
    }
    setReady(true);
  }, []);

  const handleOnSortChange = (value: string) => {
    setSort({ ...sort, value });
    setLocalStorage(`${entity}/datatable/sort/value`, String(value));
  };

  const handleOnSortOrderChange = () => {
    const order = sort.order === SortOrderOptions.ASC ? SortOrderOptions.DESC : SortOrderOptions.ASC;
    setSort({ ...sort, order });
    setLocalStorage(`${entity}/datatable/sort/order`, String(order));
  };

  return { sort, setSort, handleOnSortChange, handleOnSortOrderChange, ready };
};
