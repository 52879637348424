import { z } from 'zod';
export const paginationSchema = z.object({
  offset: z.number(),
  limit: z.number(),
  count: z.number(),
});

export const pageSizeOptions = [100, 250, 500, 750, 1000];
export const defaultPaginationIndex = 0;
export const defaultPaginationSize = pageSizeOptions[0];
export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

export const defaultPagination = {
  pageIndex: defaultPaginationIndex,
  pageSize: defaultPaginationSize,
};
