import Image from 'next/image';
import { FC, useState } from 'react';
import { cn } from '@/lib/utils';
import { Asset, Variants } from '@/types/asset';
import { Skeleton } from '@/components/ui/skeleton';
import { File } from 'lucide-react';

export const InspectorImage: FC<Asset & { stackedAssetsLength: number; index: number; classname?: string }> = ({
  id,
  stackedAssetsLength,
  name,
  index,
  variants,
  classname,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const thumbnailUrl = variants[Variants.Thumbnail]?.url;

  return (
    <div
      className={cn(
        'flex size-full origin-center overflow-hidden rounded-md shadow-lg transition-all duration-200',
        classname,
      )}
      key={`inspector-${id}`}
      style={{
        transform: `rotate(${(30 / stackedAssetsLength) * index}deg)`,
        zIndex: stackedAssetsLength - index,
      }}
    >
      {Object.entries(variants).length === 0 && thumbnailUrl && !isLoaded ? (
        <Skeleton className="size-full rounded-none" />
      ) : thumbnailUrl ? (
        <Image
          onLoad={() => setIsLoaded(true)}
          draggable="false"
          src={thumbnailUrl}
          alt={name}
          fill
          className="size-auto object-cover"
        />
      ) : (
        <div className="flex size-full items-center justify-center rounded-md bg-white hover:cursor-pointer dark:bg-neutral-950">
          <File className="size-14 stroke-neutral-500 dark:stroke-neutral-700" strokeWidth={1} />
        </div>
      )}
    </div>
  );
};
