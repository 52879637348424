import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getShare, getShareList } from '@/services/share.service';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

export const useSharesList = (
  params: {
    nodeId: string;
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  options?: object,
) => {
  const shareListQueryFn = useAuthenticatedQueryFn(getShareList);

  const {
    data: shareList = { shares: [], pagination: { count: 0, limit: 0, offset: 0 } },
    isPending: shareListIsPending,
    isFetching: shareListIsFetching,
    error: shareListError,
    refetch: refetchShareList,
  } = useQuery({
    queryKey: ['shareList', params.pagination, params.nodeId, params.queryString, params.sort],
    queryFn: () => shareListQueryFn(params),
    placeholderData: keepPreviousData,
    ...options,
  });

  return {
    shareList,
    shareListIsFetching,
    shareListIsPending,
    shareListError,
    refetchShareList,
  };
};

export const useShare = (params: { nodeId: string; shareId: string }, options: object, form: any) => {
  const getShareQueryFn = useAuthenticatedQueryFn(getShare);

  const {
    data: share = [],
    isPending: shareIsPending,
    isFetching: shareIsFetching,
    error: shareError,
    refetch: refetchShare,
  } = useQuery({
    queryKey: ['getShare', params.nodeId, params.shareId],
    queryFn: async () => {
      const data = await getShareQueryFn(params);
      form?.reset(data);
      return data;
    },
    ...options,
  });

  return {
    share,
    shareIsPending,
    refetchShare,
    shareIsFetching,
    shareError,
  };
};
