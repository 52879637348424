import { useRouter } from 'next/router';

export const PATHS = {
  HOME: '/',
  TAGS: '/manage/tags',
  USERS: '/manage/users',
  TRASH: '/trash',
  SEARCH: '/search',
  MANAGE: '/manage',
};

export const useCurrentPage = () => {
  const { pathname } = useRouter();

  return {
    isHomePage: pathname === PATHS.HOME,
    isTagsPage: pathname === PATHS.TAGS,
    isTrashPage: pathname === PATHS.TRASH,
    isSearchPage: pathname === PATHS.SEARCH,
    isUsersPage: pathname === PATHS.USERS,
    isManagePage: pathname.startsWith(PATHS.MANAGE),
  };
};
