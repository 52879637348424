import { z } from 'zod';

export const keywordSchema = z.object({
  id: z.string(),
  asset_id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  keyword: z.string(),
});

export type Keyword = z.infer<typeof keywordSchema>;
