import { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { useFormErrorHandler, BackendError } from '@/hooks/useFormErrorHandler';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { FormProvider, useForm } from 'react-hook-form';
import { kebabCase, capitalize } from 'lodash';
import { Loader2, Save } from 'lucide-react';
import { MetadataField } from '@/types/metadata';
import { saveMetadata } from '@/services/metadata.service';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderField } from '@/components/inspector/entity-fields/form/field-components';
import { useMetadata } from '@/hooks/metadata/useMetadata';
import { typeOptions } from '@/pages/manage/metadata';
import { Separator } from '@/components/ui/separator';
import { FormLabel } from '@/components/ui/form';
import { useToast } from '@/components/ui/use-toast';
import { Skeleton } from '@/components/ui/skeleton';
import { Options } from '@/components/inspector/entity-fields/form/options';
import { Button } from '@/components/ui/button';
import { log } from '@/utilities/log';
import { cn } from '@/lib/utils';
import { z } from 'zod';

export const MetadataFields = ({ metadataId }: { metadataId: string }) => {
  const queryClient = useQueryClient();

  const { metadata: fieldData, metadataIsFetching } = useMetadata(metadataId);
  const { toast } = useToast();

  const [currentAccordionItems, setCurrentAccordionItems] = useState<Array<string>>(['user-profile']);
  const [options, setOptions] = useState<Record<string, string>>(fieldData?.options?.choices ?? {});

  // Create a dynamic form schema based on the fieldData
  const createFormSchema = (data: MetadataField) => {
    const schema: Record<string, z.ZodType<any>> = {};

    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'name':
          schema[key] = z.string().min(1, { message: `${capitalize(key)} field is required.` });
          break;
        case 'description':
          schema[key] = z.string().optional();
          break;
        case 'type':
          schema[key] = z.enum(['string', 'boolean', 'float', 'date', 'text', 'select', 'multi_select'], {
            required_error: 'Type is required.',
          });
          break;
        case 'options':
          schema[key] = z
            .union([
              z.array(z.any()),
              z.object({
                choices: z.record(z.string()),
              }),
            ])
            .optional();
          break;
        case 'facet':
        case 'searchable':
        case 'public':
          schema[key] = z.boolean();
          break;
        case 'position':
          schema[key] = z.coerce.number().min(0, { message: 'Position must be a positive number.' });
          break;
        default:
          break;
      }
    });

    return z.object(schema);
  };

  const formSchema = fieldData ? createFormSchema(fieldData) : z.object({});

  // Dynamic default values based on the fieldData
  const createDefaultValues = (data: MetadataField) => {
    const defaultValues: Partial<Record<keyof MetadataField, any>> = {};

    if (data) {
      Object.keys(data).forEach((key) => {
        defaultValues[key as keyof MetadataField] = data[key as keyof MetadataField];
      });

      return defaultValues;
    }
  };

  const form = useForm({
    resolver: zodResolver(fieldData && createFormSchema(fieldData)),
    defaultValues: fieldData && createDefaultValues(fieldData),
  });

  const { watch } = form;
  const { handleError } = useFormErrorHandler(form.setError, form.getValues);

  // Watch for changes to the 'type' field and add options UI if the field is a 'select' or 'multi_select'
  const fieldType = watch('type');

  const handleAddOption = () => {
    const newKey = `option_${Object.keys(options).length + 1}`;
    setOptions((prev: Record<string, string>) => ({ ...prev, [newKey]: '' }));
  };

  const handleRemoveOption = (key: string) => {
    setOptions((prev: Record<string, string>) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  // Reset form values when fieldData changes
  useEffect(() => {
    form.reset(createDefaultValues(fieldData));
    setOptions(fieldData?.options?.choices ?? {});
  }, [fieldData, form.reset]);

  const saveMetadataWithAuth = useAuthenticatedQueryFn(saveMetadata);

  const saveMetadataMutation = useMutation({
    mutationFn: saveMetadataWithAuth,
    onSuccess: async () => {
      toast({
        title: 'Metadata Saved',
        description: 'Metadata fields have been saved successfully.',
      });

      await queryClient.invalidateQueries({ queryKey: ['metadataList'] });
    },
    onError: (err: BackendError) => {
      handleError(err);
      log(err);
    },
  });

  function onSubmit(values: z.infer<ReturnType<typeof createFormSchema>>) {
    // Remove options that are empty or contain only whitespace
    const cleanedOptions = Object.fromEntries(Object.entries(options).filter(([_, value]) => value.trim() !== ''));

    // Collect all kebab-cased keys to check for duplicates
    const keys = Object.values(cleanedOptions).map((value) => kebabCase(value as string));

    // Check for duplicate keys
    const duplicates = keys.filter((key, index) => keys.indexOf(key) !== index);
    if (duplicates.length > 0) {
      toast({
        title: 'Duplicate Options Found',
        description: 'Each option must be unique. Please ensure there are no duplicate entries.',
      });
      return; // Prevent form submission
    }

    const formattedOptions = Object.keys(cleanedOptions).length
      ? {
          choices: Object.fromEntries(
            Object.entries(cleanedOptions).map(([key, value]) => [kebabCase(value as string), value]),
          ),
        }
      : [];

    saveMetadataMutation.mutate({
      id: fieldData.id,
      metadata: {
        ...values,
        options: formattedOptions,
      },
    });
  }

  const isLoading = saveMetadataMutation.status === 'pending';

  return (
    <Accordion
      onValueChange={(accordion) => {
        setCurrentAccordionItems(accordion);
      }}
      value={currentAccordionItems}
      type="multiple"
      className="w-full"
      defaultValue={['metadata-manager']}
    >
      <AccordionItem className="border-0" value={'singleMetadataAccordion'}>
        <AccordionTrigger className="h-[49px] border-y border-b-neutral-300 border-t-[#2D2D2D] bg-neutral-200 p-3 hover:no-underline @[18rem]/inspector:py-3 dark:border-b-black dark:bg-[#222222]">
          {metadataIsFetching ? <Skeleton className="h-6 w-1/2" /> : fieldData?.name}
        </AccordionTrigger>
        <AccordionContent className="flex flex-col bg-neutral-200 p-3 dark:bg-[#1C1C1C]">
          {metadataIsFetching ? (
            <div className="flex flex-col space-y-6">
              {Array.from(Array(3)).map((_, index) => (
                <>
                  <div key={`skeleton-${index}`} className="flex flex-col space-y-3">
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/5': index === 0 })} />
                    <Skeleton className="h-8 w-full" />
                    <Skeleton className={cn('h-5 w-1/3', { 'w-2/3': index === 1 })} />
                  </div>
                  {index !== 2 && <Separator className="block" />}
                </>
              ))}
            </div>
          ) : (
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="relative flex flex-col space-y-6 @[30rem]/inspector:space-y-2 @[41rem]/inspector:mx-auto @[41rem]/inspector:w-[600px]"
              >
                {Object.keys(fieldData).map((key) => {
                  const validation = (formSchema.shape as Record<string, z.ZodType<any>>)[key];

                  switch (key) {
                    case 'name':
                      return renderField(
                        form,
                        {
                          'name': capitalize(key),
                          'type': 'string',
                          'slug': key,
                          'description': key === 'name' ? 'Name of the metadata field' : undefined,
                          'disabled': metadataIsFetching || isLoading,
                          validation,
                          'data-cy': 'metadata-name-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'description':
                      return renderField(
                        form,
                        {
                          'name': capitalize(key),
                          'type': 'text',
                          'slug': key,
                          'description': 'Description under the field or in a tooltip on smaller resolutions',
                          'disabled': metadataIsFetching || isLoading,
                          validation,
                          'data-cy': 'metadata-description-field',
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'type':
                      return (
                        <div key={key} className="flex flex-col gap-4 @[30rem]/inspector:gap-2">
                          {renderField(
                            form,
                            {
                              'name': capitalize(key),
                              'type': 'select',
                              'slug': key,
                              'options': typeOptions,
                              'disabled': metadataIsFetching || isLoading,
                              validation,
                              'data-cy': 'metadata-type-field',
                            },
                            fieldType === 'multi_select' || fieldType === 'select'
                              ? 'hidden'
                              : 'block @[24rem]/inspector:hidden',
                          )}
                          {/* Render options fields immediately after type field if fieldType is multi_select or select */}
                          {(fieldType === 'multi_select' || fieldType === 'select') && fieldData.options && (
                            <div
                              className={cn(
                                'flex flex-col space-y-2 @[30rem]/inspector:flex-row @[30rem]/inspector:gap-x-3',
                              )}
                            >
                              <div className="flex items-start gap-2 @[30rem]/inspector:w-1/2 @[30rem]/inspector:justify-end">
                                <FormLabel
                                  className={cn(
                                    'mx-2 leading-4 @[30rem]/inspector:order-2 @[30rem]/inspector:mx-0 @[30rem]/inspector:mr-1.5 @[30rem]/inspector:text-right',
                                  )}
                                >
                                  {fieldType === 'multi_select' ? 'Multi-Select Options' : 'Select Options'}
                                </FormLabel>
                              </div>
                              <div className="relative @[30rem]/inspector:!mt-0 @[30rem]/inspector:w-1/2">
                                <Options
                                  options={options}
                                  setOptions={setOptions}
                                  handleAddOption={handleAddOption}
                                  handleRemoveOption={handleRemoveOption}
                                  value={options}
                                  disabled={metadataIsFetching || isLoading}
                                />
                                <Separator className="mt-2 block @[24rem]/inspector:hidden" />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    case 'facet':
                    case 'searchable':
                    case 'public':
                      return renderField(
                        form,
                        {
                          'name': capitalize(key),
                          'type': 'boolean',
                          'slug': key,
                          'description':
                            key === 'facet'
                              ? 'Whether the tag is considered a facet. It must have children in order to appear as a facet.'
                              : key === 'searchable'
                              ? 'Whether assets with this tag applied to will appears in search results including the tag name.'
                              : key === 'public'
                              ? 'Whether the tag can be shown publicly when publishing the asset externally.'
                              : undefined,
                          'disabled': metadataIsFetching || isLoading,
                          validation,
                          'data-cy': `metadata-${key}-field`,
                        },
                        'block @[24rem]/inspector:hidden',
                      );
                    case 'position':
                      return renderField(
                        form,
                        {
                          'name': capitalize(key),
                          'type': 'float',
                          'slug': key,
                          'description': 'Position of the field in the list',
                          'disabled': metadataIsFetching || isLoading,
                          validation,
                          'min': 0,
                          'data-cy': 'metadata-position-field',
                        },
                        'hidden',
                      );
                    default:
                      return null;
                  }
                })}

                <Button
                  disabled={metadataIsFetching || isLoading}
                  type="submit"
                  variant={'default'}
                  className="w-full justify-center @[21rem]/inspector:w-auto"
                  data-cy="metadata-save-button"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="mr-2 size-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="mr-2 size-4" />
                      Save
                    </>
                  )}
                </Button>
              </form>
            </FormProvider>
          )}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
