import { z } from 'zod';

export const albumSchema = z.object({
  createdAt: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
  }),
  description: z.string(),
  destinations: z.string().array().optional(),
  id: z.string(),
  locked: z.boolean().optional(),
  name: z.string(),
  nodeId: z.string(),
  parentFolderId: z.string(),
  slug: z.string(),
  tenantId: z.string(),
  updatedAt: z.string(),
});
export type Album = z.infer<typeof albumSchema>;

const RenameAlbumParams = albumSchema
  .pick({ name: true, description: true, slug: true })
  .partial({ description: true, slug: true });
export type RenameAlbumParams = z.infer<typeof RenameAlbumParams>;

export const addAssetsResponseSchema = z.object({
  add: z.array(z.string()),
});

export type AddAssetsResponse = z.infer<typeof addAssetsResponseSchema>;

export const removeAssetsResponseSchema = z.object({
  remove: z.array(z.string()),
});

export type RemoveAssetsResponse = z.infer<typeof removeAssetsResponseSchema>;

const CreateAlbumParams = albumSchema.pick({ name: true, description: true, parentFolderId: true }).partial({
  description: true,
  parentFolderId: true,
});

export type CreateAlbumParams = z.infer<typeof CreateAlbumParams>;

const DeleteAlbumParams = albumSchema.pick({ id: true });

export type DeleteAlbumParams = z.infer<typeof DeleteAlbumParams>;

export const reorderAssetsSchema = z.object({
  order: z.object({
    position: z.number(),
    assets: z.array(z.string()),
  }),
});

export type ReorderAssetsParams = z.infer<typeof reorderAssetsSchema>;
