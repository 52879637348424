import { useCallback, useMemo } from 'react';
import { useTreeContext } from '@/context/TreeContext';
import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DefaultError, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { getNode, getRootNodes, moveNodesQuery } from '@/services/tree.service';
import { trashNodesQuery, restoreNodesQuery } from '@/services/trash.service';
import { MoveNodesResponse, TreeBranchNode, TreeRecursiveChildrenNode } from '@/types/tree';
import { AssetListQueryKeyPrefix } from '../assets/useAssetList';
import { useRouter } from 'next/router';
import { TrashNodesResponse } from '@/types/trash';
import { useAuth0 } from '@auth0/auth0-react';
import { uniq } from 'lodash';

export const useTree = () => {
  const queryClient = useQueryClient();
  const getRootNodesFn = useAuthenticatedQueryFn(getRootNodes);
  const getNodeFn = useAuthenticatedQueryFn(getNode);
  const moveNodesQueryFn = useAuthenticatedQueryFn(moveNodesQuery);
  const trashNodesQueryFn = useAuthenticatedQueryFn(trashNodesQuery);
  const restoreNodesQueryFn = useAuthenticatedQueryFn(restoreNodesQuery);
  const context = useTreeContext();

  const { query } = useRouter();
  const { openIds, updatedIds } = context;
  const { isAuthenticated } = useAuth0();

  // state
  const selectedFolder = query.folder ? String(query.folder) : undefined;
  const selectedAlbum = query.album ? String(query.album) : undefined;

  // queries
  const { data: rootNodes, isLoading: isRootNodesLoading } = useQuery({
    queryKey: ['tree'],
    queryFn: () => getRootNodesFn(null),
    enabled: isAuthenticated,
  });

  const { data: childNodes } = useQueries({
    queries: uniq([...openIds, ...updatedIds]).map((nodeId) => ({
      queryKey: ['tree', { nodeId }],
      queryFn: () => getNodeFn({ nodeId }),
    })),
    combine: (results) => ({
      data: results.map((result) => result.data),
      isLoading: results.some((result) => result.isLoading),
    }),
  });

  // mutations
  const { mutateAsync: moveNodes } = useMutation<
    MoveNodesResponse,
    DefaultError,
    Parameters<typeof moveNodesQuery>['0']
  >({
    mutationFn: (params) => moveNodesQueryFn(params),
    onSettled: () => {
      void refetchTree();
      void queryClient.invalidateQueries({ queryKey: [AssetListQueryKeyPrefix] });
    },
  });

  const { mutateAsync: trashNodes } = useMutation<
    TrashNodesResponse,
    DefaultError,
    Parameters<typeof trashNodesQuery>['0']
  >({
    mutationFn: (params) => trashNodesQueryFn(params),
    onSettled: () => {
      void refetchTree();
    },
  });

  const { mutateAsync: restoreNodes } = useMutation<
    TrashNodesResponse,
    DefaultError,
    Parameters<typeof trashNodesQuery>['0']
  >({
    mutationFn: (params) => restoreNodesQueryFn(params),
    onSettled: () => {
      void refetchTree();
    },
  });

  // transformations and callbacks
  const refetchTree = useCallback(() => queryClient.invalidateQueries({ queryKey: ['tree'] }), [queryClient]);

  // combine rootNodes and childNodes into recursive structure
  const tree = useMemo(() => {
    const appendChildren = (
      nodes: Array<TreeRecursiveChildrenNode>,
      treeBranchNode: TreeBranchNode,
    ): Array<TreeRecursiveChildrenNode> =>
      nodes.map((node) => ({
        ...node,
        children:
          node.id === treeBranchNode?.id
            ? // attach new children nodes if node matches
              treeBranchNode.children
            : // otherwise go through children and try again
              appendChildren(node.children ?? [], treeBranchNode),
      }));

    // go through every fetched childNodes and attach them to the right node in the roottree
    return childNodes.reduce((prev, current) => (current ? appendChildren(prev, current) : prev), rootNodes ?? []);
  }, [childNodes, rootNodes]);

  return {
    ...context,
    selectedAlbum,
    selectedFolder,
    tree,
    refetchTree,
    moveNodes,
    trashNodes,
    restoreNodes,
    isRootNodesLoading,
  };
};
