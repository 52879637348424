import { useCallback } from 'react';
import { FieldValues, Path, UseFormSetError, UseFormGetValues } from 'react-hook-form';
import { useToast } from '@/components/ui/use-toast';

export interface BackendError {
  errors: Record<string, Array<string>> | string;
  message: string;
}

export const useFormErrorHandler = <FormData extends FieldValues>(
  setError: UseFormSetError<FormData>,
  getValues: UseFormGetValues<FormData>,
  fallbackPath?: Path<FormData>,
) => {
  const { toast } = useToast();
  const handleError = useCallback(
    (errorObject: BackendError) => {
      if (errorObject.errors) {
        Object.entries(errorObject.errors).forEach(([field, messages]) => {
          if (Array.isArray(messages)) {
            const availablePaths = Object.keys(getValues());
            const fieldName = availablePaths.includes(field) ? field : fallbackPath ?? availablePaths[0];
            const message = messages[0]; // Assuming the first message is what we want to display

            setError(fieldName as Path<FormData>, { type: 'manual', message });
          }
        });
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: errorObject.message,
        });
      }
    },
    [getValues, fallbackPath, setError],
  );

  return { handleError };
};
