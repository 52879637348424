import { damRequest } from '@/hooks/damRequest';
import { variantSchema } from '@/types/variant';

const BASE = '/api/v1/internal/variants';

const getVariantList = async (
  params: {
    pagination: { limit: number | null; offset: number | null };
    sort: { order: string; value: string };
    queryString: string;
  },
  token?: string,
) => {
  const response = await damRequest(
    'GET',
    `${BASE}?offset=${params.pagination?.offset ?? ''}&limit=${params.pagination?.limit ?? ''}&filterQuery=${
      params.queryString
    }&sortBy=${params.sort?.value}&sortOrder=${params.sort?.order}`,
    token,
  );
  return variantSchema.parse(response);
};

const createVariant = async (body: object, token?: string) => {
  const response = await damRequest('POST', BASE, token, { body });

  return response;
};

const deleteVariant = ({ id }: { id: string }, token?: string) => damRequest('DELETE', `${BASE}/${id}`, token);

export { getVariantList, createVariant, deleteVariant };
