import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { DefaultError, useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';
import { unDeleteAlbum, deleteAlbum } from '@/services/album.service';
import { Album } from '@/types/album';
import { useTree } from '@/hooks/data/tree/useTree';
import { getAlbum } from '@/services/album.service';

export const useAlbum = (
  id?: string,
  form?: {
    reset: (data: any) => void;
  },
  options?: object,
) => {
  const getAlbumWithAuth = useAuthenticatedQueryFn(getAlbum);
  const authUnDeleteAlbum = useAuthenticatedQueryFn(unDeleteAlbum);
  const authDeleteAlbum = useAuthenticatedQueryFn(deleteAlbum);
  const { refetchTree } = useTree();

  const {
    data: album,
    isLoading: isAlbumLoading,
    isPending: isAlbumPending,
    isFetching: isAlbumFetching,
    error: albumError,
    refetch,
  } = useQuery({
    queryKey: ['album', id],
    queryFn: id
      ? async () => {
          const data = await getAlbumWithAuth(id);
          form?.reset(data);
          return data;
        }
      : () => undefined,
    placeholderData: keepPreviousData,
    enabled: false,
    ...options,
  });

  const { mutateAsync: unDeleteAlbumMutation } = useMutation<
    Album,
    DefaultError,
    Parameters<typeof unDeleteAlbum>['0']
  >({
    mutationFn: (params) => authUnDeleteAlbum(params),
    onSettled: () => {
      void refetchTree();
    },
  });

  const { mutateAsync: deleteAlbumMutation } = useMutation<Album, DefaultError, Parameters<typeof deleteAlbum>['0']>({
    mutationFn: (params) => authDeleteAlbum(params),
    onSettled: () => {
      void refetchTree();
    },
  });

  return {
    album,
    refetchAlbum: refetch,
    isAlbumFetching,
    isAlbumLoading,
    isAlbumPending,
    error: albumError,
    unDeleteAlbumMutation,
    deleteAlbumMutation,
  };
};
