import { useAuthenticatedQueryFn } from '@/hooks/useAuthenticatedQuery';
import { getFolderAssets, getAlbumAssets } from '@/services/asset.service';
import { GetAssetsRoot } from '@/types/asset';
import { SortState } from '@/types/sort';
import { useAuth0 } from '@auth0/auth0-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const AssetListQueryKeyPrefix = 'assetData';

export const useAssetList = (
  page: number,
  resultsPerPage: number,
  folderId?: string,
  albumId?: string,
  includeSubFolderAssets = false,
  sort?: SortState,
  queryString?: string,
) => {
  const folderAssetsQueryFn = useAuthenticatedQueryFn(getFolderAssets);
  const albumAssetsQueryFn = useAuthenticatedQueryFn(getAlbumAssets);
  const pagination = { limit: Number(resultsPerPage), offset: (Number(page ?? 1) - 1) * resultsPerPage };
  const { isAuthenticated } = useAuth0();

  const queryKey = folderId
    ? [AssetListQueryKeyPrefix, { folderId, includeSubFolderAssets, ...pagination }]
    : albumId
    ? [AssetListQueryKeyPrefix, { albumId, ...pagination, queryString, sort }]
    : undefined;

  const queryFn = folderId
    ? () => folderAssetsQueryFn({ folderId, pagination, includeSubFolderAssets })
    : albumId
    ? () => albumAssetsQueryFn({ albumId, pagination, queryString, sort })
    : undefined;

  const {
    data: assets,
    isLoading,
    isPending,
    error,
    refetch,
  } = useQuery<GetAssetsRoot>({
    queryKey: queryKey as ReadonlyArray<unknown>,
    queryFn: queryFn,
    placeholderData: keepPreviousData,
    enabled: Boolean(page) && Boolean(resultsPerPage) && isAuthenticated && Boolean(queryFn),
  });

  return { assets, isLoading, isPending, error, refetch };
};
