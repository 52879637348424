import { RowEntity } from '@/context/DatatableSelectionContext';
import React, { HTMLProps, Ref, useEffect, useRef } from 'react';

type VariantFieldsProps = {
  variant: RowEntity;
};

export const VariantFields = ({ variant }: VariantFieldsProps) => {
  return <div>Variants</div>;
};
