export enum TenantUserRole {
  Owner = 'owner',
  Archivist = 'archivist',
}

export type TenantUserRoleMap = {
  owner: string;
  archivist: string;
};

export const tenantUserRoleMap = {
  owner: 'Owner',
  archivist: 'Archivist',
};

export const tenantUserRoleOptions = [
  {
    label: 'Owner',
    value: 'owner',
  },
  {
    label: 'Archivist',
    value: 'archivist',
  },
];
